import React from 'react'
import { IconProps } from '../types'

export const LogoIcon = ({ className }: IconProps) => (
    <>
    {/* <?xml version="1.0" encoding="utf-8"?> */}
    <svg
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 373 100" enableBackground="new 0 0 373 100" xmlSpace="preserve" className={className}
    >
    <g>
        <g>
            <path fill="#2C394F" d="M85,0l-5,2.979l0,0L42.5,25.326L5,2.979l0,0L0.299,0.178l0,0l0,0L0,0v100l42.5-25.326L85,100V0z M5,12.809
                l20.658,22.553L5,47.672V12.809z M5,50l22.037-13.132L53.52,65.78L42.5,72.346L5,50L5,50z M31.481,34.22L42.5,27.654L80,50l0,0
                L57.963,63.132L31.481,34.22z M80,52.328v34.863L59.342,64.638L80,52.328z M80,5.308v42.364L44.453,26.49L80,5.308z M40.547,26.49
                l-10.446,6.224L5,5.31V5.308L40.547,26.49z M5,94.692V52.328L40.547,73.51L5,94.692z M54.899,67.286L80,94.69v0.003L44.453,73.51
                L54.899,67.286z"/>
        </g>
        <g>
            <path fill="#2C394F" d="M133.803,12.288v28h-1.12l-19.88-25.52v25.52h-1.36v-28h1.12l19.88,25.52v-25.52
                C132.443,12.288,133.803,12.288,133.803,12.288z"/>
            <path fill="#2C394F" d="M160.481,30.087h-18.199c0.026,1.76,0.439,3.334,1.239,4.72c0.801,1.388,1.9,2.468,3.301,3.24
                c1.399,0.774,2.966,1.16,4.699,1.16c1.413,0,2.721-0.26,3.92-0.78c1.2-0.52,2.187-1.286,2.961-2.3l0.84,0.88
                c-0.934,1.094-2.067,1.934-3.4,2.521c-1.334,0.587-2.773,0.88-4.32,0.88c-2.026,0-3.833-0.446-5.42-1.34s-2.833-2.141-3.739-3.74
                c-0.907-1.6-1.36-3.413-1.36-5.44c0-2.025,0.42-3.84,1.26-5.439c0.84-1.601,2.007-2.847,3.5-3.74s3.16-1.34,5-1.34
                c1.813,0,3.46,0.439,4.94,1.32c1.479,0.88,2.646,2.106,3.5,3.68c0.853,1.573,1.279,3.36,1.279,5.36L160.481,30.087L160.481,30.087
                z M146.561,21.648c-1.253,0.72-2.253,1.727-3,3.02c-0.746,1.294-1.159,2.74-1.239,4.34h16.92c-0.107-1.6-0.54-3.046-1.301-4.34
                c-0.76-1.293-1.76-2.3-3-3.02c-1.239-0.721-2.633-1.08-4.18-1.08S147.815,20.927,146.561,21.648z"/>
            <path fill="#2C394F" d="M185.042,10.608v29.68h-1.28v-5.68c-0.774,1.813-1.954,3.233-3.54,4.26c-1.587,1.026-3.394,1.54-5.42,1.54
                c-1.894,0-3.614-0.446-5.16-1.34c-1.547-0.894-2.767-2.141-3.66-3.74s-1.34-3.413-1.34-5.44c0-2.025,0.446-3.84,1.34-5.439
                c0.894-1.601,2.113-2.847,3.66-3.74c1.546-0.893,3.267-1.34,5.16-1.34c2,0,3.793,0.507,5.38,1.52
                c1.586,1.014,2.767,2.414,3.54,4.2V10.608H185.042z M179.382,38.028c1.346-0.786,2.406-1.887,3.18-3.3s1.16-3.026,1.16-4.841
                c0-1.813-0.387-3.42-1.16-4.819c-0.773-1.4-1.834-2.493-3.18-3.28c-1.348-0.786-2.86-1.18-4.54-1.18
                c-1.681,0-3.194,0.394-4.54,1.18c-1.347,0.787-2.407,1.88-3.181,3.28c-0.773,1.399-1.159,3.007-1.159,4.819
                c0,1.814,0.386,3.428,1.159,4.841s1.834,2.514,3.181,3.3c1.346,0.787,2.859,1.18,4.54,1.18
                C176.521,39.207,178.034,38.815,179.382,38.028z"/>
            <path fill="#2C394F" d="M206.421,21.288c1.319,1.28,1.979,3.134,1.979,5.56v13.44h-1.28v-4.16
                c-0.667,1.334-1.659,2.38-2.979,3.141c-1.32,0.76-2.9,1.14-4.74,1.14c-2.32,0-4.14-0.533-5.46-1.601
                c-1.32-1.065-1.979-2.479-1.979-4.239c0-1.681,0.6-3.047,1.8-4.101c1.2-1.053,3.12-1.58,5.76-1.58h7.56v-2.04
                c0-2.053-0.54-3.612-1.619-4.68c-1.08-1.066-2.674-1.6-4.78-1.6c-1.467,0-2.84,0.254-4.12,0.76c-1.28,0.507-2.374,1.187-3.28,2.04
                l-0.72-0.96c1.013-0.96,2.233-1.706,3.66-2.24c1.426-0.533,2.926-0.8,4.5-0.8C203.2,19.368,205.101,20.007,206.421,21.288z
                M207.08,34.288v-4.32h-7.56c-2.16,0-3.74,0.407-4.74,1.221c-1,0.814-1.5,1.913-1.5,3.3c0,1.493,0.554,2.667,1.66,3.52
                c1.106,0.854,2.62,1.28,4.54,1.28C203.214,39.288,205.747,37.622,207.08,34.288z"/>
            <path fill="#2C394F" d="M217.301,39.648c-1.48-0.507-2.634-1.174-3.46-2l0.64-1.04c0.854,0.746,1.953,1.373,3.3,1.88
                s2.78,0.76,4.301,0.76c2.239,0,3.893-0.38,4.96-1.14c1.065-0.761,1.6-1.82,1.6-3.181c0-0.96-0.287-1.72-0.86-2.279
                c-0.573-0.561-1.286-0.974-2.14-1.24s-2-0.533-3.44-0.8c-1.68-0.32-3.02-0.646-4.02-0.98c-1-0.333-1.847-0.873-2.54-1.62
                c-0.693-0.746-1.04-1.786-1.04-3.12c0-1.572,0.646-2.886,1.94-3.939c1.293-1.054,3.18-1.58,5.659-1.58
                c1.28,0,2.561,0.193,3.841,0.58c1.279,0.387,2.319,0.899,3.119,1.54l-0.68,1.04c-0.827-0.641-1.794-1.127-2.899-1.46
                c-1.107-0.333-2.247-0.5-3.421-0.5c-2.054,0-3.6,0.394-4.64,1.18c-1.04,0.787-1.56,1.82-1.56,3.1c0,1.015,0.293,1.814,0.88,2.4
                c0.586,0.587,1.313,1.021,2.18,1.3c0.866,0.28,2.046,0.567,3.54,0.86c1.6,0.294,2.894,0.606,3.88,0.939
                c0.986,0.334,1.82,0.86,2.5,1.58c0.68,0.721,1.021,1.721,1.021,3c0,1.681-0.681,3.014-2.04,4c-1.36,0.987-3.32,1.48-5.88,1.48
                C220.36,40.408,218.78,40.155,217.301,39.648z"/>
            <path fill="#2C394F" d="M251.76,21.627c1.493,1.507,2.239,3.66,2.239,6.46v12.2h-1.319v-12.12c0-2.453-0.62-4.326-1.86-5.62
                c-1.24-1.293-3.007-1.939-5.3-1.939c-2.587,0-4.627,0.767-6.12,2.3c-1.494,1.533-2.24,3.594-2.24,6.18v11.2h-1.319v-29.68h1.319
                v13.88c0.693-1.601,1.773-2.854,3.24-3.76c1.466-0.906,3.213-1.36,5.24-1.36C248.226,19.368,250.266,20.122,251.76,21.627z"/>
            <path fill="#2C394F" d="M269.76,28.648l-5.8,5.319v6.32h-1.319v-29.68h1.319v21.76l14.08-12.84h1.76l-9,8.319l9.92,12.44h-1.68
                L269.76,28.648z"/>
            <path fill="#2C394F" d="M285.12,13.707c-0.267-0.253-0.4-0.553-0.4-0.9c0-0.373,0.127-0.686,0.38-0.939
                c0.254-0.253,0.566-0.38,0.94-0.38c0.346,0,0.653,0.127,0.92,0.38c0.267,0.254,0.4,0.554,0.4,0.9s-0.134,0.653-0.4,0.92
                s-0.574,0.399-0.92,0.399C285.693,14.087,285.386,13.961,285.12,13.707z M285.4,19.528h1.319v20.76H285.4V19.528z"/>
            <path fill="#2C394F" d="M311.64,19.528l-9.48,20.76h-1.32l-9.479-20.76h1.439l8.721,19.279l8.72-19.279H311.64z"/>
            <path fill="#2C394F" d="M316.34,39.648c-1.48-0.507-2.634-1.174-3.46-2l0.64-1.04c0.854,0.746,1.953,1.373,3.3,1.88
                s2.78,0.76,4.301,0.76c2.239,0,3.893-0.38,4.96-1.14c1.065-0.761,1.6-1.82,1.6-3.181c0-0.96-0.287-1.72-0.86-2.279
                c-0.573-0.561-1.286-0.974-2.14-1.24s-2-0.533-3.44-0.8c-1.68-0.32-3.02-0.646-4.02-0.98c-1-0.333-1.847-0.873-2.54-1.62
                c-0.693-0.746-1.04-1.786-1.04-3.12c0-1.572,0.646-2.886,1.94-3.939c1.293-1.054,3.18-1.58,5.659-1.58
                c1.28,0,2.561,0.193,3.841,0.58c1.279,0.387,2.319,0.899,3.119,1.54l-0.68,1.04c-0.827-0.641-1.794-1.127-2.899-1.46
                c-1.107-0.333-2.247-0.5-3.421-0.5c-2.054,0-3.6,0.394-4.64,1.18c-1.04,0.787-1.56,1.82-1.56,3.1c0,1.015,0.293,1.814,0.88,2.4
                c0.586,0.587,1.313,1.021,2.18,1.3c0.866,0.28,2.046,0.567,3.54,0.86c1.6,0.294,2.894,0.606,3.88,0.939
                c0.986,0.334,1.82,0.86,2.5,1.58c0.68,0.721,1.021,1.721,1.021,3c0,1.681-0.681,3.014-2.04,4c-1.36,0.987-3.32,1.48-5.88,1.48
                C319.399,40.408,317.819,40.155,316.34,39.648z"/>
            <path fill="#2C394F" d="M341.999,28.648l-5.8,5.319v6.32h-1.319v-29.68h1.319v21.76l14.08-12.84h1.76l-9,8.319l9.92,12.44h-1.68
                L341.999,28.648z"/>
            <path fill="#2C394F" d="M373,19.528l-10.881,23.88c-0.773,1.733-1.653,2.96-2.64,3.68s-2.146,1.08-3.479,1.08
                c-0.935,0-1.801-0.153-2.601-0.46s-1.479-0.767-2.04-1.38l0.76-1.04c0.561,0.613,1.146,1.053,1.761,1.32
                c0.612,0.266,1.319,0.399,2.12,0.399c1.039,0,1.939-0.294,2.699-0.88c0.761-0.587,1.474-1.6,2.141-3.04l1.319-2.84l-9.439-20.72
                h1.439l8.721,19.24l8.72-19.24h1.4L373,19.528L373,19.528z"/>
        </g>
        <g>
            <path fill="#2C394F" d="M128.803,58.608v29.68h-1.28v-5.68c-0.774,1.813-1.954,3.233-3.54,4.26c-1.587,1.026-3.394,1.54-5.42,1.54
                c-1.894,0-3.614-0.446-5.16-1.34c-1.547-0.894-2.767-2.141-3.66-3.74s-1.34-3.413-1.34-5.44c0-2.025,0.446-3.84,1.34-5.439
                c0.894-1.601,2.113-2.847,3.66-3.74c1.546-0.893,3.267-1.34,5.16-1.34c2,0,3.793,0.507,5.38,1.52
                c1.586,1.014,2.767,2.414,3.54,4.2V58.608H128.803z M123.143,86.028c1.346-0.786,2.406-1.887,3.18-3.3s1.16-3.026,1.16-4.841
                c0-1.813-0.387-3.42-1.16-4.819c-0.773-1.4-1.834-2.493-3.18-3.28c-1.348-0.786-2.86-1.18-4.54-1.18
                c-1.681,0-3.194,0.394-4.54,1.18c-1.347,0.787-2.407,1.88-3.181,3.28c-0.773,1.399-1.159,3.007-1.159,4.819
                c0,1.814,0.386,3.428,1.159,4.841s1.834,2.514,3.181,3.3c1.346,0.787,2.859,1.18,4.54,1.18
                C120.282,87.207,121.795,86.815,123.143,86.028z"/>
            <path fill="#2C394F" d="M154.802,78.087h-18.199c0.026,1.76,0.439,3.334,1.239,4.72c0.801,1.388,1.9,2.468,3.301,3.24
                c1.399,0.774,2.966,1.16,4.699,1.16c1.413,0,2.721-0.26,3.92-0.78c1.2-0.52,2.187-1.286,2.961-2.3l0.84,0.88
                c-0.934,1.094-2.067,1.934-3.4,2.521c-1.334,0.587-2.773,0.88-4.32,0.88c-2.026,0-3.833-0.446-5.42-1.34s-2.833-2.141-3.739-3.74
                c-0.907-1.6-1.36-3.413-1.36-5.44c0-2.025,0.42-3.84,1.26-5.439c0.84-1.601,2.007-2.847,3.5-3.74s3.16-1.34,5-1.34
                c1.813,0,3.46,0.439,4.94,1.32c1.479,0.88,2.646,2.106,3.5,3.68c0.853,1.573,1.279,3.36,1.279,5.36L154.802,78.087
                C154.803,78.087,154.802,78.087,154.802,78.087z M140.882,69.648c-1.253,0.72-2.253,1.727-3,3.02
                c-0.746,1.294-1.159,2.74-1.239,4.34h16.92c-0.107-1.6-0.54-3.046-1.301-4.34c-0.76-1.293-1.76-2.3-3-3.02
                c-1.239-0.721-2.633-1.08-4.18-1.08S142.136,68.927,140.882,69.648z"/>
            <path fill="#2C394F" d="M161.582,87.648c-1.48-0.507-2.634-1.174-3.46-2l0.64-1.04c0.854,0.746,1.953,1.373,3.3,1.88
                s2.78,0.76,4.301,0.76c2.239,0,3.893-0.38,4.96-1.14c1.065-0.761,1.6-1.82,1.6-3.181c0-0.96-0.287-1.72-0.86-2.279
                c-0.573-0.561-1.286-0.974-2.14-1.24s-2-0.533-3.44-0.8c-1.68-0.32-3.02-0.646-4.02-0.98c-1-0.333-1.847-0.873-2.54-1.62
                c-0.693-0.746-1.04-1.786-1.04-3.12c0-1.572,0.646-2.886,1.94-3.939c1.293-1.054,3.18-1.58,5.659-1.58
                c1.28,0,2.561,0.193,3.841,0.58c1.279,0.387,2.319,0.899,3.119,1.54l-0.68,1.04c-0.827-0.641-1.794-1.127-2.899-1.46
                c-1.107-0.333-2.247-0.5-3.421-0.5c-2.054,0-3.6,0.394-4.64,1.18c-1.04,0.787-1.56,1.82-1.56,3.1c0,1.015,0.293,1.814,0.88,2.4
                c0.586,0.587,1.313,1.021,2.18,1.3c0.866,0.28,2.046,0.567,3.54,0.86c1.6,0.294,2.894,0.606,3.88,0.939
                c0.986,0.334,1.82,0.86,2.5,1.58c0.68,0.721,1.021,1.721,1.021,3c0,1.681-0.681,3.014-2.04,4c-1.36,0.987-3.32,1.48-5.88,1.48
                C164.642,88.408,163.061,88.155,161.582,87.648z"/>
            <path fill="#2C394F" d="M179.841,61.707c-0.267-0.253-0.4-0.553-0.4-0.9c0-0.373,0.127-0.686,0.38-0.939
                c0.254-0.253,0.566-0.38,0.94-0.38c0.346,0,0.653,0.127,0.92,0.38c0.267,0.254,0.4,0.554,0.4,0.9s-0.134,0.653-0.4,0.92
                s-0.574,0.399-0.92,0.399C180.414,62.087,180.106,61.961,179.841,61.707z M180.121,67.528h1.319v20.76h-1.319V67.528z"/>
            <path fill="#2C394F" d="M208.6,67.528v18.76c0,3.36-0.813,5.847-2.439,7.46c-1.627,1.613-4.08,2.42-7.36,2.42
                c-1.92,0-3.707-0.294-5.36-0.88c-1.653-0.587-2.974-1.414-3.96-2.48l0.761-1c1.065,1.014,2.326,1.794,3.779,2.341
                c1.453,0.546,3.033,0.819,4.74,0.819c2.906,0,5.054-0.687,6.44-2.06c1.386-1.374,2.08-3.527,2.08-6.46v-4.4
                c-0.774,1.707-1.96,3.054-3.561,4.04c-1.6,0.987-3.427,1.48-5.479,1.48c-1.947,0-3.7-0.434-5.261-1.3
                c-1.56-0.867-2.786-2.066-3.68-3.601c-0.894-1.533-1.34-3.273-1.34-5.22s0.446-3.687,1.34-5.22s2.12-2.727,3.68-3.58
                c1.561-0.854,3.313-1.28,5.261-1.28c2.053,0,3.886,0.494,5.5,1.479c1.612,0.987,2.806,2.348,3.58,4.08v-5.399L208.6,67.528
                L208.6,67.528z M202.899,85.228c1.374-0.761,2.453-1.82,3.24-3.181c0.786-1.359,1.181-2.893,1.181-4.6
                c0-1.706-0.395-3.233-1.181-4.58c-0.787-1.347-1.866-2.394-3.24-3.14c-1.373-0.747-2.913-1.12-4.619-1.12
                c-1.707,0-3.247,0.373-4.62,1.12c-1.374,0.746-2.447,1.793-3.221,3.14s-1.159,2.874-1.159,4.58c0,1.733,0.386,3.273,1.159,4.62
                s1.847,2.399,3.221,3.16c1.373,0.76,2.913,1.14,4.62,1.14C199.986,86.368,201.526,85.988,202.899,85.228z"/>
            <path fill="#2C394F" d="M233.4,69.627c1.493,1.507,2.239,3.66,2.239,6.46v12.2h-1.319v-12.12c0-2.453-0.62-4.326-1.86-5.62
                c-1.24-1.293-3.007-1.939-5.3-1.939c-2.587,0-4.627,0.767-6.12,2.3c-1.494,1.533-2.24,3.594-2.24,6.18v11.2h-1.319v-20.76h1.279
                v5.08c0.667-1.627,1.74-2.906,3.221-3.84c1.479-0.934,3.246-1.4,5.3-1.4C229.866,67.368,231.906,68.122,233.4,69.627z"/>
            <path fill="#2C394F" d="M254.659,87.648c-1.48-0.507-2.634-1.174-3.46-2l0.64-1.04c0.854,0.746,1.953,1.373,3.3,1.88
                s2.78,0.76,4.301,0.76c2.239,0,3.893-0.38,4.96-1.14c1.065-0.761,1.6-1.82,1.6-3.181c0-0.96-0.287-1.72-0.86-2.279
                c-0.573-0.561-1.286-0.974-2.14-1.24s-2-0.533-3.44-0.8c-1.68-0.32-3.02-0.646-4.02-0.98c-1-0.333-1.847-0.873-2.54-1.62
                c-0.693-0.746-1.04-1.786-1.04-3.12c0-1.572,0.646-2.886,1.94-3.939c1.293-1.054,3.18-1.58,5.659-1.58
                c1.28,0,2.561,0.193,3.841,0.58c1.279,0.387,2.319,0.899,3.119,1.54l-0.68,1.04c-0.827-0.641-1.794-1.127-2.899-1.46
                c-1.107-0.333-2.247-0.5-3.421-0.5c-2.054,0-3.6,0.394-4.64,1.18c-1.04,0.787-1.56,1.82-1.56,3.1c0,1.015,0.293,1.814,0.88,2.4
                c0.586,0.587,1.313,1.021,2.18,1.3c0.866,0.28,2.046,0.567,3.54,0.86c1.6,0.294,2.894,0.606,3.88,0.939
                c0.986,0.334,1.82,0.86,2.5,1.58c0.68,0.721,1.021,1.721,1.021,3c0,1.681-0.681,3.014-2.04,4c-1.36,0.987-3.32,1.48-5.88,1.48
                C257.719,88.408,256.139,88.155,254.659,87.648z"/>
            <path fill="#2C394F" d="M282.999,86.967c-0.48,0.454-1.094,0.808-1.84,1.061c-0.747,0.254-1.521,0.38-2.32,0.38
                c-1.68,0-2.98-0.474-3.9-1.42s-1.38-2.246-1.38-3.9v-14.4h-3.92v-1.16h3.92v-4.561h1.32v4.561h6.84v1.16h-6.84v14.199
                c0,1.414,0.346,2.494,1.04,3.24c0.693,0.747,1.706,1.12,3.04,1.12c1.333,0,2.453-0.413,3.359-1.24L282.999,86.967z"/>
            <path fill="#2C394F" d="M306.639,67.528v20.76h-1.28v-5.04c-0.64,1.627-1.667,2.894-3.08,3.8c-1.414,0.907-3.094,1.36-5.04,1.36
                c-2.693,0-4.8-0.746-6.319-2.24c-1.521-1.493-2.28-3.64-2.28-6.439v-12.2h1.319v12.12c0,2.453,0.634,4.326,1.9,5.62
                c1.267,1.293,3.073,1.939,5.42,1.939c2.48,0,4.44-0.767,5.88-2.3c1.44-1.533,2.16-3.593,2.16-6.18v-11.2L306.639,67.528
                L306.639,67.528z"/>
            <path fill="#2C394F" d="M333.559,58.608v29.68h-1.28v-5.68c-0.774,1.813-1.954,3.233-3.54,4.26c-1.587,1.026-3.394,1.54-5.42,1.54
                c-1.894,0-3.614-0.446-5.16-1.34c-1.547-0.894-2.767-2.141-3.66-3.74s-1.34-3.413-1.34-5.44c0-2.025,0.446-3.84,1.34-5.439
                c0.894-1.601,2.113-2.847,3.66-3.74c1.546-0.893,3.267-1.34,5.16-1.34c2,0,3.793,0.507,5.38,1.52
                c1.586,1.014,2.767,2.414,3.54,4.2V58.608H333.559z M327.898,86.028c1.346-0.786,2.406-1.887,3.18-3.3s1.16-3.026,1.16-4.841
                c0-1.813-0.387-3.42-1.16-4.819c-0.773-1.4-1.834-2.493-3.18-3.28c-1.348-0.786-2.86-1.18-4.54-1.18
                c-1.681,0-3.194,0.394-4.54,1.18c-1.347,0.787-2.407,1.88-3.181,3.28c-0.773,1.399-1.159,3.007-1.159,4.819
                c0,1.814,0.386,3.428,1.159,4.841s1.834,2.514,3.181,3.3c1.346,0.787,2.859,1.18,4.54,1.18
                C325.038,87.207,326.551,86.815,327.898,86.028z"/>
            <path fill="#2C394F" d="M342.158,61.707c-0.267-0.253-0.4-0.553-0.4-0.9c0-0.373,0.127-0.686,0.38-0.939
                c0.254-0.253,0.566-0.38,0.94-0.38c0.346,0,0.653,0.127,0.92,0.38c0.267,0.254,0.4,0.554,0.4,0.9s-0.134,0.653-0.4,0.92
                s-0.574,0.399-0.92,0.399C342.731,62.087,342.424,61.961,342.158,61.707z M342.438,67.528h1.319v20.76h-1.319V67.528z"/>
            <path fill="#2C394F" d="M355.257,87.068c-1.56-0.894-2.779-2.141-3.659-3.74c-0.881-1.6-1.32-3.413-1.32-5.44
                c0-2.025,0.439-3.84,1.32-5.439c0.88-1.601,2.1-2.847,3.659-3.74c1.561-0.893,3.301-1.34,5.221-1.34
                c1.946,0,3.692,0.447,5.239,1.34c1.547,0.894,2.761,2.14,3.641,3.74s1.32,3.414,1.32,5.439c0,2.027-0.44,3.841-1.32,5.44
                s-2.094,2.847-3.641,3.74s-3.293,1.34-5.239,1.34C358.558,88.408,356.817,87.961,355.257,87.068z M365.018,86.028
                c1.346-0.786,2.406-1.887,3.18-3.3s1.16-3.026,1.16-4.841c0-1.813-0.387-3.42-1.16-4.819c-0.773-1.4-1.834-2.493-3.18-3.28
                c-1.348-0.786-2.86-1.18-4.54-1.18c-1.681,0-3.194,0.394-4.54,1.18c-1.347,0.787-2.407,1.88-3.181,3.28
                c-0.773,1.399-1.159,3.007-1.159,4.819c0,1.814,0.386,3.428,1.159,4.841s1.834,2.514,3.181,3.3c1.346,0.787,2.859,1.18,4.54,1.18
                C362.157,87.207,363.67,86.815,365.018,86.028z"/>
        </g>
    </g>
    <g display="none">
        <path display="inline" fill="#352622" d="M86.452,0l-5.085,2.979L43.226,25.326L5.085,2.979L0.304,0.178L0,0v100l43.226-25.326
            L86.452,100V0z M5.085,12.809l21.011,22.553L5.085,47.672V12.809z M5.085,50l22.413-13.132L54.434,65.78l-11.208,6.566L5.085,50z
            M32.019,34.22l11.207-6.566L81.367,50L58.953,63.132L32.019,34.22z M81.367,52.328v34.863L60.356,64.638L81.367,52.328z
            M81.367,5.308v42.364L45.212,26.49L81.367,5.308z M41.24,26.49l-10.625,6.224L5.085,5.31V5.308L41.24,26.49z M5.085,94.692V52.328
            L41.24,73.51L5.085,94.692z M55.837,67.286l25.53,27.404v0.003L45.212,73.51L55.837,67.286z"/>
        <path display="inline" fill="#352622" d="M136.717,8.574v28h-1.139l-20.219-25.52v25.52h-1.383v-28h1.139l20.219,25.52V8.574
            H136.717z"/>
        <path display="inline" fill="#352622" d="M163.851,26.374h-18.51c0.026,1.76,0.447,3.334,1.26,4.72
            c0.815,1.388,1.933,2.468,3.357,3.24c1.423,0.774,3.017,1.16,4.779,1.16c1.437,0,2.767-0.26,3.987-0.78
            c1.221-0.52,2.224-1.286,3.011-2.3l0.855,0.88c-0.95,1.094-2.102,1.934-3.458,2.521c-1.357,0.587-2.82,0.88-4.394,0.88
            c-2.061,0-3.898-0.446-5.513-1.34c-1.614-0.894-2.881-2.141-3.803-3.74c-0.923-1.6-1.383-3.413-1.383-5.44
            c0-2.025,0.427-3.84,1.281-5.439c0.854-1.601,2.041-2.847,3.56-3.74s3.214-1.34,5.085-1.34c1.844,0,3.519,0.439,5.025,1.32
            c1.504,0.88,2.691,2.106,3.56,3.68c0.867,1.573,1.301,3.36,1.301,5.36L163.851,26.374z M149.693,17.935
            c-1.274,0.72-2.291,1.727-3.051,3.02c-0.759,1.294-1.179,2.74-1.26,4.34h17.209c-0.109-1.6-0.549-3.046-1.323-4.34
            c-0.773-1.293-1.79-2.3-3.051-3.02c-1.26-0.721-2.678-1.08-4.251-1.08C152.392,16.855,150.969,17.214,149.693,17.935z"/>
        <path display="inline" fill="#352622" d="M188.831,6.895v29.68h-1.302v-5.68c-0.787,1.813-1.987,3.233-3.601,4.26
            c-1.614,1.026-3.452,1.54-5.513,1.54c-1.926,0-3.676-0.446-5.248-1.34c-1.573-0.894-2.814-2.141-3.723-3.74
            c-0.908-1.599-1.363-3.413-1.363-5.44c0-2.025,0.453-3.84,1.363-5.439c0.909-1.601,2.149-2.847,3.723-3.74
            c1.572-0.893,3.323-1.34,5.248-1.34c2.034,0,3.858,0.507,5.472,1.52c1.613,1.014,2.814,2.414,3.6,4.2V6.895H188.831z
            M183.075,34.315c1.369-0.786,2.447-1.887,3.234-3.3c0.787-1.413,1.18-3.026,1.18-4.841c0-1.813-0.393-3.42-1.18-4.819
            c-0.786-1.4-1.865-2.493-3.234-3.28c-1.371-0.786-2.909-1.18-4.617-1.18c-1.71,0-3.249,0.394-4.618,1.18
            c-1.37,0.787-2.448,1.88-3.235,3.28c-0.786,1.399-1.179,3.007-1.179,4.819c0,1.814,0.393,3.428,1.179,4.841
            c0.786,1.413,1.865,2.514,3.235,3.3c1.369,0.787,2.908,1.18,4.618,1.18C180.165,35.493,181.703,35.101,183.075,34.315z"/>
        <path display="inline" fill="#352622" d="M210.576,17.574c1.341,1.28,2.013,3.134,2.013,5.56v13.44h-1.302v-4.16
            c-0.678,1.334-1.687,2.38-3.03,3.141c-1.343,0.76-2.95,1.14-4.821,1.14c-2.359,0-4.211-0.533-5.553-1.601
            c-1.343-1.065-2.013-2.479-2.013-4.239c0-1.681,0.61-3.047,1.831-4.101c1.22-1.053,3.173-1.58,5.858-1.58h7.689v-2.04
            c0-2.053-0.549-3.612-1.647-4.68c-1.099-1.066-2.72-1.6-4.862-1.6c-1.492,0-2.889,0.254-4.19,0.76
            c-1.302,0.507-2.415,1.187-3.336,2.04l-0.733-0.96c1.031-0.96,2.271-1.706,3.723-2.24c1.451-0.533,2.976-0.8,4.577-0.8
            C207.3,15.654,209.233,16.293,210.576,17.574z M211.246,30.574v-4.32h-7.689c-2.197,0-3.804,0.407-4.821,1.221
            c-1.017,0.814-1.525,1.913-1.525,3.3c0,1.493,0.563,2.667,1.688,3.52c1.125,0.854,2.665,1.28,4.617,1.28
            C207.314,35.574,209.891,33.908,211.246,30.574z"/>
        <path display="inline" fill="#352622" d="M221.641,35.935c-1.505-0.507-2.679-1.174-3.519-2l0.651-1.04
            c0.869,0.746,1.986,1.373,3.356,1.88c1.37,0.507,2.827,0.76,4.375,0.76c2.277,0,3.959-0.38,5.045-1.14
            c1.083-0.761,1.627-1.82,1.627-3.181c0-0.96-0.292-1.72-0.875-2.279c-0.583-0.561-1.308-0.974-2.176-1.24
            c-0.869-0.266-2.034-0.533-3.499-0.8c-1.709-0.32-3.072-0.646-4.089-0.98c-1.017-0.333-1.879-0.873-2.583-1.62
            c-0.705-0.746-1.058-1.786-1.058-3.12c0-1.572,0.657-2.886,1.973-3.939c1.315-1.054,3.234-1.58,5.755-1.58
            c1.302,0,2.605,0.193,3.907,0.58c1.301,0.387,2.359,0.899,3.172,1.54l-0.691,1.04c-0.841-0.641-1.825-1.127-2.949-1.46
            c-1.126-0.333-2.285-0.5-3.479-0.5c-2.089,0-3.661,0.394-4.719,1.18c-1.058,0.787-1.587,1.82-1.587,3.1
            c0,1.015,0.298,1.814,0.895,2.4c0.596,0.587,1.335,1.021,2.217,1.3c0.881,0.28,2.081,0.567,3.601,0.86
            c1.627,0.294,2.943,0.606,3.946,0.939c1.003,0.334,1.851,0.86,2.543,1.58c0.692,0.721,1.039,1.721,1.039,3
            c0,1.681-0.693,3.014-2.075,4c-1.383,0.987-3.377,1.48-5.981,1.48C224.752,36.695,223.145,36.442,221.641,35.935z"/>
        <path display="inline" fill="#352622" d="M256.689,17.914c1.519,1.507,2.277,3.66,2.277,6.46v12.2h-1.341v-12.12
            c0-2.453-0.631-4.326-1.892-5.62c-1.261-1.293-3.058-1.939-5.391-1.939c-2.631,0-4.706,0.767-6.225,2.3
            c-1.519,1.533-2.278,3.594-2.278,6.18v11.2h-1.341V6.895h1.341v13.88c0.705-1.601,1.803-2.854,3.295-3.76
            c1.491-0.906,3.268-1.36,5.329-1.36C253.094,15.655,255.169,16.409,256.689,17.914z"/>
        <path display="inline" fill="#352622" d="M274.996,24.934l-5.899,5.319v6.32h-1.342V6.893h1.342v21.76l14.32-12.84h1.79
            l-9.154,8.319l10.089,12.44h-1.708L274.996,24.934z"/>
        <path display="inline" fill="#352622" d="M290.617,9.994c-0.271-0.253-0.406-0.553-0.406-0.9c0-0.373,0.129-0.686,0.386-0.939
            c0.26-0.253,0.577-0.38,0.957-0.38c0.351,0,0.663,0.127,0.934,0.38c0.274,0.254,0.409,0.554,0.409,0.9s-0.137,0.653-0.409,0.92
            c-0.269,0.267-0.583,0.399-0.934,0.399C291.2,10.374,290.889,10.248,290.617,9.994z M290.903,15.815h1.343v20.76h-1.343V15.815z"/>
        <path display="inline" fill="#352622" d="M317.591,15.815l-9.64,20.76h-1.343l-9.643-20.76h1.466l8.869,19.279l8.869-19.279
            L317.591,15.815L317.591,15.815z"/>
        <path display="inline" fill="#352622" d="M322.371,35.935c-1.503-0.507-2.677-1.174-3.517-2l0.651-1.04
            c0.869,0.746,1.986,1.373,3.354,1.88c1.371,0.507,2.829,0.76,4.374,0.76c2.277,0,3.96-0.38,5.046-1.14
            c1.083-0.761,1.629-1.82,1.629-3.181c0-0.96-0.294-1.72-0.877-2.279c-0.583-0.561-1.306-0.974-2.174-1.24s-2.034-0.533-3.5-0.8
            c-1.709-0.32-3.071-0.646-4.089-0.98c-1.017-0.333-1.877-0.873-2.583-1.62c-0.706-0.746-1.057-1.786-1.057-3.12
            c0-1.572,0.657-2.886,1.971-3.939c1.317-1.054,3.234-1.58,5.757-1.58c1.3,0,2.603,0.193,3.906,0.58
            c1.3,0.387,2.36,0.899,3.171,1.54l-0.691,1.04c-0.84-0.641-1.823-1.127-2.949-1.46s-2.286-0.5-3.48-0.5
            c-2.089,0-3.66,0.394-4.717,1.18c-1.057,0.787-1.589,1.82-1.589,3.1c0,1.015,0.3,1.814,0.897,2.4
            c0.594,0.587,1.334,1.021,2.217,1.3c0.88,0.28,2.08,0.567,3.6,0.86c1.626,0.294,2.943,0.606,3.946,0.939
            c1.003,0.334,1.851,0.86,2.543,1.58c0.691,0.721,1.037,1.721,1.037,3c0,1.681-0.691,3.014-2.074,4
            c-1.383,0.987-3.377,1.48-5.98,1.48C325.483,36.695,323.877,36.442,322.371,35.935z"/>
        <path display="inline" fill="#352622" d="M348.469,24.934l-5.897,5.319v6.32h-1.343V6.893h1.343v21.76l14.32-12.84h1.789
            l-9.151,8.319l10.089,12.44h-1.709L348.469,24.934z"/>
        <path display="inline" fill="#352622" d="M380,15.814l-11.066,23.88c-0.786,1.733-1.683,2.96-2.686,3.68s-2.183,1.08-3.54,1.08
            c-0.949,0-1.831-0.153-2.643-0.46c-0.814-0.307-1.506-0.767-2.077-1.38l0.774-1.04c0.571,0.613,1.166,1.053,1.791,1.32
            c0.623,0.266,1.34,0.399,2.154,0.399c1.057,0,1.974-0.294,2.746-0.88c0.774-0.587,1.5-1.6,2.177-3.04l1.343-2.84l-9.6-20.72h1.463
            l8.871,19.24l8.869-19.24H380V15.814z"/>
        <path display="inline" fill="#352622" d="M131.632,62.323v29.68h-1.302v-5.68c-0.787,1.813-1.987,3.233-3.601,4.26
            c-1.614,1.026-3.452,1.54-5.513,1.54c-1.926,0-3.676-0.446-5.248-1.34c-1.573-0.894-2.814-2.141-3.723-3.74
            c-0.908-1.599-1.363-3.413-1.363-5.44c0-2.025,0.454-3.84,1.363-5.439c0.909-1.601,2.149-2.847,3.723-3.74
            c1.572-0.893,3.323-1.34,5.248-1.34c2.034,0,3.858,0.507,5.472,1.52c1.613,1.014,2.814,2.414,3.601,4.2V62.323H131.632z
            M125.875,89.743c1.369-0.786,2.447-1.887,3.234-3.3c0.787-1.413,1.18-3.026,1.18-4.841c0-1.813-0.394-3.42-1.18-4.819
            c-0.786-1.4-1.865-2.493-3.234-3.28c-1.371-0.786-2.909-1.18-4.617-1.18c-1.71,0-3.249,0.394-4.618,1.18
            c-1.37,0.787-2.448,1.88-3.235,3.28c-0.786,1.399-1.179,3.007-1.179,4.819c0,1.814,0.393,3.428,1.179,4.841s1.865,2.514,3.235,3.3
            c1.369,0.787,2.908,1.18,4.618,1.18C122.965,90.922,124.504,90.53,125.875,89.743z"/>
        <path display="inline" fill="#352622" d="M158.075,81.802h-18.51c0.027,1.76,0.447,3.334,1.26,4.72
            c0.815,1.388,1.932,2.468,3.357,3.24c1.423,0.774,3.017,1.16,4.779,1.16c1.437,0,2.767-0.26,3.987-0.78
            c1.221-0.52,2.225-1.286,3.012-2.3l0.854,0.88c-0.95,1.094-2.102,1.934-3.458,2.521c-1.357,0.587-2.82,0.88-4.394,0.88
            c-2.061,0-3.899-0.446-5.513-1.34s-2.881-2.141-3.803-3.74c-0.922-1.6-1.383-3.413-1.383-5.44c0-2.025,0.427-3.84,1.281-5.439
            c0.855-1.601,2.041-2.847,3.56-3.74c1.518-0.893,3.214-1.34,5.085-1.34c1.844,0,3.519,0.439,5.024,1.32
            c1.504,0.88,2.691,2.106,3.56,3.68c0.868,1.573,1.301,3.36,1.301,5.36L158.075,81.802L158.075,81.802z M143.917,73.363
            c-1.274,0.72-2.291,1.727-3.051,3.02c-0.759,1.294-1.179,2.74-1.26,4.34h17.209c-0.109-1.6-0.549-3.046-1.323-4.34
            c-0.773-1.293-1.79-2.3-3.051-3.02c-1.26-0.721-2.678-1.08-4.251-1.08C146.616,72.283,145.193,72.642,143.917,73.363z"/>
        <path display="inline" fill="#352622" d="M164.971,91.363c-1.505-0.507-2.679-1.174-3.519-2l0.651-1.04
            c0.869,0.746,1.986,1.373,3.356,1.88c1.37,0.507,2.827,0.76,4.375,0.76c2.277,0,3.959-0.38,5.045-1.14
            c1.083-0.761,1.627-1.82,1.627-3.181c0-0.96-0.292-1.72-0.875-2.279c-0.583-0.561-1.308-0.974-2.177-1.24s-2.034-0.533-3.499-0.8
            c-1.709-0.32-3.072-0.646-4.089-0.98c-1.017-0.333-1.878-0.873-2.583-1.62c-0.705-0.746-1.058-1.786-1.058-3.12
            c0-1.572,0.657-2.886,1.973-3.939c1.315-1.054,3.234-1.58,5.756-1.58c1.302,0,2.605,0.193,3.907,0.58
            c1.301,0.387,2.359,0.899,3.172,1.54l-0.692,1.04c-0.841-0.641-1.825-1.127-2.948-1.46c-1.126-0.333-2.285-0.5-3.479-0.5
            c-2.089,0-3.661,0.394-4.719,1.18c-1.058,0.787-1.587,1.82-1.587,3.1c0,1.015,0.298,1.814,0.895,2.4
            c0.596,0.587,1.335,1.021,2.217,1.3c0.881,0.28,2.081,0.567,3.601,0.86c1.627,0.294,2.943,0.606,3.946,0.939
            c1.003,0.334,1.851,0.86,2.543,1.58c0.692,0.721,1.039,1.721,1.039,3c0,1.681-0.693,3.014-2.075,4
            c-1.383,0.987-3.377,1.48-5.981,1.48C168.083,92.123,166.475,91.87,164.971,91.363z"/>
        <path display="inline" fill="#352622" d="M183.541,65.422c-0.271-0.253-0.407-0.553-0.407-0.9c0-0.373,0.129-0.686,0.387-0.939
            s0.576-0.38,0.956-0.38c0.352,0,0.664,0.127,0.936,0.38c0.272,0.254,0.407,0.554,0.407,0.9s-0.136,0.653-0.407,0.92
            s-0.584,0.399-0.936,0.399C184.124,65.802,183.811,65.676,183.541,65.422z M183.826,71.243h1.341v20.76h-1.341V71.243z"/>
        <path display="inline" fill="#352622" d="M212.792,71.242v18.76c0,3.36-0.827,5.847-2.481,7.46c-1.655,1.613-4.15,2.42-7.486,2.42
            c-1.953,0-3.77-0.294-5.451-0.88c-1.681-0.587-3.025-1.414-4.028-2.48l0.774-1c1.083,1.014,2.366,1.794,3.843,2.341
            c1.478,0.546,3.085,0.819,4.821,0.819c2.955,0,5.14-0.687,6.55-2.06c1.409-1.374,2.115-3.527,2.115-6.46v-4.4
            c-0.787,1.707-1.993,3.054-3.622,4.04c-1.627,0.987-3.486,1.48-5.573,1.48c-1.98,0-3.763-0.434-5.351-1.3
            c-1.587-0.867-2.833-2.066-3.743-3.601c-0.909-1.533-1.363-3.273-1.363-5.22s0.454-3.687,1.363-5.22
            c0.909-1.533,2.156-2.727,3.743-3.58c1.588-0.854,3.37-1.28,5.351-1.28c2.088,0,3.953,0.494,5.594,1.479
            c1.64,0.987,2.854,2.348,3.641,4.08v-5.399L212.792,71.242z M206.994,88.942c1.397-0.761,2.495-1.82,3.295-3.181
            c0.799-1.359,1.201-2.893,1.201-4.6c0-1.706-0.402-3.233-1.201-4.58c-0.801-1.347-1.898-2.394-3.295-3.14
            c-1.397-0.747-2.963-1.12-4.698-1.12c-1.736,0-3.302,0.373-4.699,1.12c-1.397,0.746-2.489,1.793-3.276,3.14
            s-1.179,2.874-1.179,4.58c0,1.733,0.393,3.273,1.179,4.62s1.879,2.399,3.276,3.16c1.397,0.76,2.963,1.14,4.699,1.14
            C204.031,90.082,205.597,89.702,206.994,88.942z"/>
        <path display="inline" fill="#352622" d="M238.015,73.342c1.518,1.507,2.277,3.66,2.277,6.46v12.2h-1.341v-12.12
            c0-2.453-0.631-4.326-1.892-5.62c-1.261-1.293-3.059-1.939-5.391-1.939c-2.631,0-4.706,0.767-6.225,2.3
            c-1.52,1.533-2.278,3.594-2.278,6.18v11.2h-1.342v-20.76h1.301v5.08c0.679-1.627,1.77-2.906,3.276-3.84
            c1.504-0.934,3.301-1.4,5.39-1.4C234.421,71.083,236.496,71.837,238.015,73.342z"/>
        <path display="inline" fill="#352622" d="M259.638,91.363c-1.505-0.507-2.679-1.174-3.519-2l0.651-1.04
            c0.869,0.746,1.986,1.373,3.356,1.88c1.37,0.507,2.828,0.76,4.375,0.76c2.277,0,3.959-0.38,5.045-1.14
            c1.083-0.761,1.627-1.82,1.627-3.181c0-0.96-0.292-1.72-0.875-2.279c-0.583-0.561-1.308-0.974-2.177-1.24s-2.034-0.533-3.499-0.8
            c-1.709-0.32-3.071-0.646-4.089-0.98c-1.017-0.333-1.879-0.873-2.583-1.62c-0.705-0.746-1.058-1.786-1.058-3.12
            c0-1.572,0.657-2.886,1.973-3.939c1.315-1.054,3.234-1.58,5.756-1.58c1.302,0,2.605,0.193,3.907,0.58
            c1.301,0.387,2.359,0.899,3.172,1.54l-0.692,1.04c-0.841-0.641-1.825-1.127-2.949-1.46c-1.126-0.333-2.285-0.5-3.479-0.5
            c-2.089,0-3.661,0.394-4.719,1.18c-1.058,0.787-1.587,1.82-1.587,3.1c0,1.015,0.298,1.814,0.895,2.4
            c0.596,0.587,1.335,1.021,2.217,1.3c0.881,0.28,2.081,0.567,3.601,0.86c1.627,0.294,2.943,0.606,3.946,0.939
            c1.003,0.334,1.851,0.86,2.543,1.58c0.691,0.721,1.038,1.721,1.038,3c0,1.681-0.693,3.014-2.075,4
            c-1.383,0.987-3.377,1.48-5.98,1.48C262.75,92.123,261.143,91.87,259.638,91.363z"/>
        <path display="inline" fill="#352622" d="M288.46,90.681c-0.486,0.454-1.111,0.808-1.871,1.061c-0.757,0.254-1.546,0.38-2.358,0.38
            c-1.709,0-3.031-0.474-3.967-1.42s-1.403-2.246-1.403-3.9v-14.4h-3.987v-1.16h3.987v-4.561h1.343v4.561h6.957v1.16h-6.957v14.199
            c0,1.414,0.352,2.494,1.058,3.24c0.705,0.747,1.735,1.12,3.092,1.12c1.356,0,2.496-0.413,3.416-1.24L288.46,90.681z"/>
        <path display="inline" fill="#352622" d="M312.506,71.243v20.76h-1.303v-5.04c-0.651,1.627-1.694,2.894-3.131,3.8
            c-1.44,0.907-3.149,1.36-5.126,1.36c-2.74,0-4.883-0.746-6.429-2.24c-1.546-1.493-2.32-3.64-2.32-6.439v-12.2h1.343v12.12
            c0,2.453,0.646,4.326,1.931,5.62c1.289,1.293,3.126,1.939,5.514,1.939c2.523,0,4.514-0.767,5.98-2.3
            c1.466-1.533,2.197-3.593,2.197-6.18v-11.2L312.506,71.243L312.506,71.243z"/>
        <path display="inline" fill="#352622" d="M339.886,62.323v29.68h-1.303v-5.68c-0.786,1.813-1.986,3.233-3.6,4.26
            c-1.614,1.026-3.451,1.54-5.511,1.54c-1.926,0-3.677-0.446-5.249-1.34c-1.574-0.894-2.814-2.141-3.723-3.74
            s-1.363-3.413-1.363-5.44c0-2.025,0.454-3.84,1.363-5.439c0.909-1.601,2.149-2.847,3.723-3.74c1.571-0.893,3.323-1.34,5.249-1.34
            c2.034,0,3.857,0.507,5.471,1.52c1.611,1.014,2.814,2.414,3.6,4.2V62.323H339.886z M334.129,89.743
            c1.369-0.786,2.446-1.887,3.234-3.3c0.786-1.413,1.18-3.026,1.18-4.841c0-1.813-0.394-3.42-1.18-4.819
            c-0.786-1.4-1.866-2.493-3.234-3.28c-1.371-0.786-2.909-1.18-4.617-1.18c-1.711,0-3.249,0.394-4.62,1.18
            c-1.369,0.787-2.446,1.88-3.234,3.28c-0.786,1.399-1.177,3.007-1.177,4.819c0,1.814,0.391,3.428,1.177,4.841s1.866,2.514,3.234,3.3
            c1.371,0.787,2.909,1.18,4.62,1.18C331.22,90.922,332.757,90.53,334.129,89.743z"/>
        <path display="inline" fill="#352622" d="M348.631,65.422c-0.271-0.253-0.409-0.553-0.409-0.9c0-0.373,0.131-0.686,0.389-0.939
            c0.257-0.253,0.574-0.38,0.954-0.38c0.351,0,0.666,0.127,0.937,0.38c0.271,0.254,0.406,0.554,0.406,0.9s-0.134,0.653-0.406,0.92
            c-0.271,0.267-0.586,0.399-0.937,0.399C349.214,65.802,348.9,65.676,348.631,65.422z M348.914,71.243h1.343v20.76h-1.343V71.243z"
            />
        <path display="inline" fill="#352622" d="M361.954,90.783c-1.589-0.894-2.826-2.141-3.723-3.74c-0.894-1.6-1.343-3.413-1.343-5.44
            c0-2.025,0.449-3.84,1.343-5.439c0.897-1.601,2.137-2.847,3.723-3.74s3.357-1.34,5.309-1.34c1.98,0,3.757,0.447,5.329,1.34
            c1.574,0.894,2.809,2.14,3.703,3.74c0.897,1.6,1.343,3.414,1.343,5.439c0,2.027-0.446,3.841-1.343,5.44
            c-0.894,1.599-2.129,2.847-3.703,3.74c-1.571,0.893-3.349,1.34-5.329,1.34C365.311,92.123,363.54,91.676,361.954,90.783z
            M371.88,89.743c1.371-0.786,2.449-1.887,3.234-3.3c0.789-1.413,1.18-3.026,1.18-4.841c0-1.813-0.391-3.42-1.18-4.819
            c-0.786-1.4-1.863-2.493-3.234-3.28c-1.369-0.786-2.909-1.18-4.617-1.18c-1.709,0-3.249,0.394-4.617,1.18
            c-1.369,0.787-2.449,1.88-3.234,3.28c-0.786,1.399-1.18,3.007-1.18,4.819c0,1.814,0.394,3.428,1.18,4.841
            c0.786,1.413,1.866,2.514,3.234,3.3c1.369,0.787,2.909,1.18,4.617,1.18C368.971,90.922,370.511,90.53,371.88,89.743z"/>
    </g>
    </svg>
</>
)