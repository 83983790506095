import React, {
    useEffect,
    useState
} from 'react'
import { NewCircleStudio } from '../../../icons'
import { useScrollBlock } from '../../../hooks/useScrollBlock'
import './style.css'

export const Preloader = () => {
    const [ loaded, setLoaded ] = useState<boolean | null>(null)

    const { allowScroll, blockScroll } = useScrollBlock();

    useEffect(() => {
        const startTimeout = setTimeout(() => {
            setLoaded(true)
            blockScroll()
        }, 100)

        const endTimeout = setTimeout(() => {
            allowScroll()
        }, 3300)

        return () => {
            clearTimeout(startTimeout)
            clearTimeout(endTimeout)
        }
    }, [])

    return (
        <div className={`preloader${loaded ? ' loaded' : ''}`}>
            <div className='preloader__inner'>
                <div className='preloader__inner-loader'>
                    <NewCircleStudio className='preloader__inner-logo'/>
                </div>
            </div>
            <div className='preloader__section preloader__top'></div>
            <div className='preloader__section preloader__bottom'></div>
        </div>
    );
}