import React from 'react'
import { IconProps } from '../types'

export const CircleStudioIcon = ({ className }: IconProps) => (
    <svg width='420' height='460' viewBox='0 0 420 460' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
        <circle cx='210' cy='230' r='209.25' stroke='#BD7052' stroke-width='1.5'/>
        <circle cx='210' cy='20' r='20' fill='#F4F3F5'/>
        <path d='M197.061 20L210 7.06066L222.939 20L210 32.9393L197.061 20Z' stroke='#BD7052' stroke-width='1.5'/>
        <circle cx='392' cy='125' r='20' transform='rotate(-90 392 125)' fill='#F4F3F5'/>
        <path d='M379.061 125L392 112.061L404.939 125L392 137.939L379.061 125Z' stroke='#BD7052' stroke-width='1.5'/>
        <circle cx='392' cy='335' r='20' transform='rotate(-90 392 335)' fill='#F4F3F5'/>
        <path d='M379.061 335L392 322.061L404.939 335L392 347.939L379.061 335Z' stroke='#BD7052' stroke-width='1.5'/>
        <circle cx='210' cy='440' r='20' fill='#F4F3F5'/>
        <path d='M197.061 440L210 427.061L222.939 440L210 452.939L197.061 440Z' stroke='#BD7052' stroke-width='1.5'/>
        <circle cx='28' cy='335' r='20' transform='rotate(-90 28 335)' fill='#F4F3F5'/>
        <path d='M15.0607 335L28 322.061L40.9393 335L28 347.939L15.0607 335Z' stroke='#BD7052' stroke-width='1.5'/>
        <circle cx='28' cy='125' r='20' fill='#F4F3F5'/>
        <path d='M15.0607 125L28 112.061L40.9393 125L28 137.939L15.0607 125Z' stroke='#BD7052' stroke-width='1.5'/>
    </svg>
)