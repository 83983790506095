import React, { ChangeEvent } from 'react'

interface ClassNames {
    root?: string;
    input?: string;
    label?: string;
    labelText?: string;
}

interface FormInputProps {
    id: string;
    label: string;
    value?: string;
    isSubmit?: boolean;
    classNames?: ClassNames;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormInput = (
    {
        id,
        label,
        value,
        classNames,
        isSubmit = false,
        onChange,
    }: FormInputProps,
) => (
    <div className={`footer__request-form__input ${classNames?.root ?? ''}`}>
        <input
            className={`${classNames?.input ?? ''}${isSubmit ? ' isSubmit' : ''}`}
            id={id}
            type='text'
            name={id}
            onChange={onChange}
            value={value}
            required
        />
        <label
            className={`label ${classNames?.label ?? ''}${isSubmit ? ' isSubmit' : ''}`}
            htmlFor={id}
        >
            <span className={`label-name ${classNames?.labelText ?? ''}${isSubmit ? ' isSubmit' : ''}`}>{label}</span>
        </label>
    </div>
)