import React, {
    useEffect,
    useState
} from 'react'
import { Text } from '../../../types'
import { Nav } from './Nav'
import { Languages } from './Languages'
import './Header.css'
import { LogoIcon } from '../../../icons'

interface HeaderProps {
    urlPrefix: string;
    language: string;
    navigation: Text[];
}

export const Header = (
    {
        urlPrefix,
        language,
        navigation
    }: HeaderProps
) => {
    const [ isCollapsed, setIsCollapsed ] = useState(true)
    const [ isScrollTop, setIsScrollTop ] = useState(true)
    const [ isVisible, setIsVisible ] = useState(false)

    useEffect(() => {
        document.onscroll = () => handleOnScroll()
    }, [])

    const handleOnScroll = () => {
        const scroll = window.scrollY

        if (scroll >= 60) {
            setIsScrollTop(false)
        } else {
            setIsScrollTop(true)
        }
    }

    const handleClickCollapse = () => {
        const body = document.querySelector('body')

        setIsVisible(!isVisible)
        setIsCollapsed(!isCollapsed)

        if (body) {
            if (isCollapsed) {
                body.style.overflowY = 'hidden'
            } else {
                body.style.overflowY = 'auto'
            }
        }
    }

    return (
        <header className={`header ${isScrollTop ? '' : 'scroll'}`}>
            <div className='header__logotype'>
                <a
                    className='header__logotype-link'
                    href={(language === 'ua') ? `/` : `/${language}`}
                >
                    <LogoIcon className='header__logotype-image'/>
                </a>
            </div>

            <div
                className={`header__nav ${!isCollapsed ? 'open' : ''}`}
            >
                <Nav
                    language={language}
                    projects={navigation.find((text: Text) => text.key === 'nav-projects')?.value || 'Проекти'}
                    services={navigation.find((text: Text) => text.key === 'nav-services')?.value || 'Послуги'}
                    about={navigation.find((text: Text) => text.key === 'nav-about')?.value || 'Про нас'}
                    contacts={navigation.find((text: Text) => text.key === 'nav-contacts')?.value || 'Контакти'}
                    isCollapsed={isVisible}
                />

                <Languages
                    urlPrefix={urlPrefix}
                    language={language}
                    isCollapsed={isVisible}
                />
            </div>

            <div
                className={`header__collapsed ${!isCollapsed ? 'open' : ''}`}
                onClick={handleClickCollapse}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </header>
    )
}