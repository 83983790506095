import React, { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { sendEmail, EmailProps } from '../../../api/email'
import { FormInput } from './formInput';

export interface FormData {
    name?: string;
    phone?: string;
    email?: string;
    message?: string;
}

export const RequestForm = ({ requestForm }) => {
    const [formData, setFormData] = useState<FormData>({})
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }, [formData, setFormData])

    const handleFormSubmit = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        setIsLoading(true)

        if (formData?.email && formData?.name && formData?.phone) {
            sendEmail(formData as EmailProps)
        }

        setTimeout(() => {
            setIsLoading(false)
        }, 2000)

        setIsSubmit(true)
    }, [formData, setIsLoading, sendEmail, setIsSubmit])

    const isSubmitDisabled = useMemo(() => (
        !Boolean(formData?.email && formData?.name && formData?.phone && formData?.message)
        || isSubmit
        || isLoading
    ), [formData, isLoading, isSubmit]);

    return (
        <>
            {isSubmit && (
                <Helmet>
                    <script>
                    {`
                        window.dataLayer = window.dataLayer || [];

                        dataLayer.push({
                        'event': 'FormSend'
                        });
                    `}
                    </script>
                </Helmet>
            )}
            {isSubmit && (
                <noscript>
                    <iframe
                        src    = "https://www.googletagmanager.com/ns.html?id=GTM-K5QHFRB"
                        height = "0"
                        width  = "0"
                        style  = {{
                            display   : 'none',
                            visibility: 'hidden'
                        }}
                    />
                </noscript>
            )}
            <div className='footer__request'>
                <h2 className='footer__request-header'>{requestForm.header}</h2>
                <form className='footer__request-form'>
                    <FormInput
                        id='name'
                        label={requestForm.name}
                        value={formData?.name}
                        isSubmit={isSubmit}
                        onChange={handleOnChange}
                    />
                    <FormInput
                        id='phone'
                        label={requestForm.phone}
                        value={formData?.phone}
                        isSubmit={isSubmit}
                        onChange={handleOnChange}
                    />
                    <FormInput
                        id='email'
                        label={requestForm.email}
                        value={formData?.email}
                        isSubmit={isSubmit}
                        onChange={handleOnChange}
                    />
                    <FormInput
                        id='message'
                        label={requestForm.message}
                        value={formData?.message}
                        isSubmit={isSubmit}
                        onChange={handleOnChange}
                    />
                    <div className='footer__request-form__send'>
                        <button
                            className={`btn btn-primary send-email ${isSubmit ? 'btn-thanks' : ''}`}
                            onClick={handleFormSubmit}
                            disabled={isSubmitDisabled}
                        >
                            {isSubmit ? requestForm.thanks : requestForm.button}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}
