import React, {
    useEffect,
    useState
} from 'react'
import './Languages.css'

export const Languages = ({ urlPrefix, language, isCollapsed }) => {
    const [windowWidth, setWindowWidth] = useState<number>(768)

    useEffect(() => {
        setWindowWidth(window.outerWidth)
    }, [])

    return (
        <div
            className='header__language'
            style={{
                display: `${windowWidth <= 768 ? (!isCollapsed ? 'none' : 'flex') : 'block'}`
            }}
        >
            <a className={`header__language-link ${language === 'ua' && 'active'}`} href={'/' + urlPrefix}>UA</a>
            <a className={`header__language-link ${language === 'en' && 'active'}`} href={'/en/' + urlPrefix}>EN</a>
        </div>
    )
}