import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { sendEmail, EmailProps } from '../../../api/email'
import { FormInput } from './formInput'
import './style.css'

const TIMER = 45000;

interface FormData {
    name?: string;
    phone?: string;
    email?: string;
}

export const PriceForm = ({ form }) => {
    const [priceFormClosed, setPriceFormClosed] = useState<boolean>(false)
    const [openForm, setOpenForm] = useState(false);
    const [formData, setFormData] = useState<FormData>({});
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)

    useEffect(() => {
        const priceFormState: boolean = Boolean(sessionStorage.getItem('priceFormClosed') === 'true')

        if (priceFormState) {
            setPriceFormClosed(priceFormState);
        }
    }, [])

    useEffect(() => {
        let timeout: any = null

        if (priceFormClosed === false) {
            timeout = setTimeout(() => setOpenForm(true), TIMER)
        }

        return () => clearTimeout(timeout)
    }, [priceFormClosed, setOpenForm])

    const handleClickClose = useCallback(() => {
        sessionStorage.setItem('priceFormClosed', 'true')
        setPriceFormClosed(true)
        setOpenForm(false)
    }, [setPriceFormClosed, setOpenForm])

    const handleChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [event.target.name] : event.target.value
        })
    }, [formData, setFormData])

    const handleSubmitForm = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        if (formData.name && formData.phone && formData.email) {
            setIsLoading(true)

            sendEmail({
                ...formData,
                message: 'Дізнатися ціну!'
            } as EmailProps)

            setTimeout(() => {
                setIsLoading(false)
                setIsSubmit(true)
            }, 2000)

            setTimeout(() => {
                handleClickClose()
            }, 5000)
        }
    }, [formData])

    const isSubmitDisabled = useMemo(() => (
        !Boolean(formData?.email && formData?.name && formData?.phone)
        || isSubmit
        || isLoading
    ), [formData, isLoading, isSubmit]);

    if (priceFormClosed === false && openForm) {
        return (
            <>
                {isSubmit && (
                    <>
                        <Helmet>
                            <script>
                            {`
                                window.dataLayer = window.dataLayer || [];

                                dataLayer.push({
                                'event': 'FormSend'
                                });
                            `}
                            </script>
                        </Helmet>
                        <Helmet
                            script={[{
                                type: 'text/javascript',
                                innerHTML: `fbq('track', 'Lead');`
                            }]}
                        />
                    </>
                )}
                <div className='priceForm'>
                    <div className='priceForm__container'>
                        <div
                            className='priceForm__close'
                            onClick={handleClickClose}
                        />
                        <div className='priceForm__header'>Дізнайтесь ціну простору вашої мрії</div>
                        <div className='priceForm__subHeader'>залишивши контакти</div>

                        <form className='priceForm__form'>
                            <div className='priceForm__form-two'>
                                <FormInput
                                    id='name'
                                    value={formData.name}
                                    label={form.name}
                                    onChange={handleChangeInput}
                                />

                                <FormInput
                                    id='phone'
                                    value={formData.phone}
                                    label={form.phone}
                                    onChange={handleChangeInput}
                                />
                            </div>

                            <FormInput
                                classNames={{
                                    root: 'priceForm__form-lastInput',
                                }}
                                id='email'
                                value={formData.email}
                                label={form.email}
                                onChange={handleChangeInput}
                            />

                            <div className='footer__request-form__send'>
                                <button
                                    className={`btn btn-primary send-email priceForm__form-submit ${isSubmit ? 'btn-thanks' : ''}`}
                                    onClick={handleSubmitForm}
                                    disabled={isSubmitDisabled}
                                >
                                    {isSubmit
                                        ? form.thanks
                                        : !isLoading ? form.button : 'Loading...'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }

    return null;
}