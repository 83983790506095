import React, { PropsWithChildren, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Header } from '../ui-components/header/Header'
import { Footer } from '../ui-components/footer/Footer'
import { Preloader } from '../ui-components/preloader'
import { Text } from '../../types'
import '../layout.css'

interface MainLayoutProps {
	urlPrefix: string;
	language: string;
}

export const MainLayout = (
	{
		urlPrefix,
		language,
		children,
	}: PropsWithChildren<MainLayoutProps>
) => {
	const query = useStaticQuery(graphql`
		query {
			keystone {
				allTexts {
					language
					key
					value
				}
				allContacts {
					language
					location
					address
					addressLink
					email
					clientNumber
					contractorNumber
				}
			}
		}
	`)

	const {
		allTexts: texts,
		allContacts: contacts
	} = query.keystone

	const footerTexts  = useMemo(() => (
		texts.filter((text: Text) => text.key.includes('contacts') && text.language === language)
	), [texts])
	const contactsData = useMemo(() => (
		contacts.filter((contact) => contact.language === language)
	), [contacts])
	const navigation   = useMemo(() => (
		texts.filter((text: Text) => text.key.includes('nav-') && text.language === language)
	), [texts])

	return (
		<>
			<Helmet>
				<link
					href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&amp;display=swap'
					rel  = 'stylesheet'
				/>
				<script>
					{`
						!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
						n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
						n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
						t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
						document,'script','https://connect.facebook.net/en_US/fbevents.js');
						fbq('init', '586215602139383');
						fbq('track', 'PageView');
					`}
				</script>
				<script async src="https://www.googletagmanager.com/gtag/js?id=UA-202081902-1"></script>
				<script>
					{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());

						gtag('config', 'UA-202081902-1');
					`}
				</script>
				<script>
					{`
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-K5QHFRB');
					`}
				</script>
				{`<!-- Hotjar Tracking Code for Ndesign -->`}
				<script>
					{`
						(function(h,o,t,j,a,r){
							h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
							h._hjSettings={hjid:3765080,hjsv:6};
							a=o.getElementsByTagName('head')[0];
							r=o.createElement('script');r.async=1;
							r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
							a.appendChild(r);
						})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
					`}
				</script>
				{`<!-- Google Tag Manager -->`}
				<script>
					{`
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-N5MMSJV8');
					`}
				</script>
				{`<!-- End Google Tag Manager -->`}
			</Helmet>
			{`<!-- Google Tag Manager (noscript) -->`}
			<noscript>
				{`
					<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N5MMSJV8"
					height="0" width="0" style="display:none;visibility:hidden"></iframe>
				`}
			</noscript>
			{`<!-- End Google Tag Manager (noscript) -->`}
			<Header
				urlPrefix={urlPrefix}
				language={language}
				navigation={navigation}
			/>
			<Preloader />
			<main>{children}</main>
			<Footer
				texts={footerTexts}
				contactsData={contactsData[0]}
				language={language}
			/>
		</>
	)
}
