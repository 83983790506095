import React from 'react'
import { IconProps } from '../types'

export const NewCircleStudio = ({ className }: IconProps) => (
    <svg width='737' height='737' viewBox='0 0 737 737' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
        <path d='M191.753 546.395C192.031 546.674 192.169 547.006 192.168 547.392C192.167 547.777 192.022 548.113 191.733 548.402C191.454 548.679 191.122 548.817 190.737 548.816C190.352 548.815 190.02 548.675 189.743 548.396C189.465 548.117 189.327 547.785 189.327 547.4C189.328 547.015 189.468 546.683 189.747 546.406C190.036 546.118 190.374 545.974 190.759 545.975C191.144 545.976 191.475 546.116 191.753 546.395Z' fill='currentColor'/>
        <path d='M148.23 510.263L161.442 501.446L161.972 502.239L156.51 518.905L167.986 511.246L168.629 512.21L155.416 521.027L154.874 520.215L160.349 503.568L148.873 511.226L148.23 510.263Z' fill='currentColor'/>
        <path d='M141.353 476.951L145.871 486.089C146.685 485.653 147.321 485.078 147.777 484.363C148.22 483.655 148.454 482.881 148.478 482.042C148.495 481.189 148.296 480.342 147.88 479.501C147.538 478.809 147.1 478.232 146.568 477.769C146.015 477.299 145.397 476.988 144.714 476.836L145.094 475.914C145.878 476.1 146.594 476.473 147.241 477.031C147.881 477.576 148.403 478.255 148.805 479.069C149.329 480.127 149.564 481.184 149.512 482.24C149.46 483.295 149.145 484.262 148.565 485.138C147.98 486.002 147.165 486.691 146.12 487.207C145.089 487.716 144.06 487.955 143.031 487.922C141.982 487.883 141.036 487.591 140.193 487.045C139.349 486.5 138.689 485.745 138.213 484.782C137.737 483.819 137.541 482.843 137.627 481.855C137.692 480.861 138.024 479.946 138.623 479.109C139.216 478.26 140.028 477.58 141.059 477.071L141.353 476.951ZM139.108 484.34C139.477 485.086 139.979 485.674 140.613 486.103C141.234 486.54 141.94 486.79 142.731 486.855C143.502 486.913 144.281 486.782 145.068 486.461L141.043 478.32C140.303 478.735 139.726 479.274 139.31 479.935C138.894 480.596 138.665 481.309 138.621 482.074C138.577 482.838 138.739 483.593 139.108 484.34Z' fill='currentColor'/>
        <path d='M119.206 452L135.116 446.485L135.481 447.536L132.844 448.45C133.87 448.591 134.758 448.98 135.507 449.617C136.257 450.254 136.808 451.081 137.161 452.096C137.523 453.139 137.608 454.167 137.415 455.179C137.221 456.191 136.78 457.089 136.091 457.873C135.398 458.642 134.5 459.217 133.4 459.599C132.299 459.98 131.238 460.084 130.216 459.909C129.176 459.726 128.273 459.294 127.508 458.614C126.729 457.938 126.159 457.079 125.796 456.035C125.449 455.034 125.374 454.059 125.573 453.109C125.767 452.145 126.206 451.288 126.89 450.538L119.578 453.073L119.206 452ZM136.173 452.366C135.875 451.508 135.41 450.812 134.776 450.279C134.138 449.732 133.394 449.389 132.545 449.25C131.691 449.098 130.807 449.18 129.892 449.497C128.977 449.814 128.232 450.297 127.656 450.945C127.076 451.579 126.704 452.308 126.542 453.134C126.375 453.944 126.441 454.779 126.739 455.636C127.037 456.494 127.505 457.197 128.143 457.745C128.777 458.278 129.521 458.621 130.374 458.773C131.223 458.912 132.105 458.822 133.02 458.505C133.935 458.188 134.682 457.712 135.263 457.079C135.839 456.43 136.21 455.701 136.377 454.89C136.539 454.065 136.471 453.224 136.173 452.366Z' fill='currentColor'/>
        <path d='M116.652 424.568C116.34 423.133 116.471 421.951 117.043 421.022C117.6 420.096 118.589 419.479 120.009 419.172L127.351 417.582L127.582 418.646L125.519 419.093C126.28 419.316 126.929 419.74 127.468 420.367C127.992 420.997 128.361 421.807 128.576 422.798C128.856 424.085 128.774 425.171 128.332 426.056C127.886 426.927 127.168 427.47 126.177 427.684C125.216 427.893 124.366 427.721 123.627 427.168C122.869 426.605 122.327 425.568 121.999 424.06L121.137 420.089L120.205 420.291C119.126 420.524 118.369 420.998 117.936 421.711C117.499 422.41 117.403 423.321 117.647 424.445C117.814 425.214 118.108 425.925 118.528 426.577C118.945 427.214 119.429 427.729 119.978 428.121L119.3 428.849C118.657 428.384 118.107 427.775 117.649 427.023C117.177 426.273 116.844 425.455 116.652 424.568ZM127.633 422.84C127.425 421.878 127.026 421.113 126.438 420.543C125.847 419.959 125.092 419.588 124.176 419.431L122.002 419.901L122.86 423.85C123.107 424.989 123.494 425.772 124.02 426.2C124.543 426.614 125.167 426.742 125.892 426.585C126.646 426.421 127.184 426.011 127.506 425.353C127.812 424.698 127.855 423.86 127.633 422.84Z' fill='currentColor'/>
        <path d='M124.04 396.045C124.142 396.993 124.097 397.911 123.903 398.799C123.71 399.687 123.421 400.396 123.036 400.924L122.078 400.501C122.432 399.976 122.704 399.323 122.894 398.542C123.082 397.745 123.131 396.934 123.042 396.106C122.914 394.917 122.624 394.066 122.173 393.551C121.72 393.021 121.148 392.793 120.456 392.867C119.959 392.921 119.585 393.121 119.333 393.467C119.08 393.799 118.91 394.197 118.823 394.663C118.736 395.129 118.661 395.769 118.596 396.583C118.531 397.533 118.432 398.305 118.298 398.898C118.164 399.49 117.901 400.013 117.508 400.466C117.116 400.919 116.536 401.187 115.769 401.269C114.836 401.369 114.027 401.068 113.343 400.365C112.641 399.648 112.214 398.576 112.06 397.146C111.979 396.394 112.004 395.638 112.135 394.878C112.266 394.118 112.479 393.486 112.775 392.983L113.734 393.405C113.44 393.924 113.235 394.501 113.121 395.138C113.007 395.774 112.985 396.415 113.055 397.062C113.173 398.161 113.466 398.967 113.932 399.48C114.398 399.993 114.962 400.214 115.624 400.143C116.151 400.087 116.548 399.884 116.814 399.536C117.066 399.189 117.243 398.782 117.345 398.315C117.43 397.834 117.511 397.171 117.587 396.325C117.653 395.39 117.755 394.633 117.89 394.056C118.009 393.464 118.258 392.951 118.637 392.514C119.016 392.078 119.574 391.82 120.312 391.741C121.289 391.637 122.116 391.959 122.79 392.709C123.464 393.458 123.881 394.57 124.04 396.045Z' fill='currentColor'/>
        <path d='M110.432 368.113C110.445 366.631 110.886 365.462 111.756 364.607C112.625 363.752 113.87 363.332 115.489 363.347L122.412 363.409L122.402 364.543L115.57 364.482C114.238 364.47 113.222 364.802 112.52 365.476C111.818 366.136 111.463 367.071 111.452 368.282C111.441 369.674 111.848 370.774 112.673 371.584C113.499 372.393 114.615 372.804 116.023 372.817L122.333 372.873L122.323 374.008L105.481 373.857L105.491 372.723L112.958 372.789C112.145 372.374 111.522 371.763 111.09 370.957C110.642 370.151 110.423 369.203 110.432 368.113Z' fill='currentColor'/>
        <path d='M117.963 337.098L120.342 340.533L123.829 341.003L123.678 342.128L106.985 339.878L107.136 338.754L118.947 340.345L113.094 331.907L113.287 330.467L117.355 336.169L125.096 331.555L124.909 332.95L117.963 337.098Z' fill='currentColor'/>
        <path d='M117.14 310.175L117.404 309.071L128.927 311.839L128.662 312.943L117.14 310.175ZM114.469 308.949C114.409 309.199 114.277 309.393 114.072 309.531C113.854 309.665 113.619 309.702 113.369 309.642C113.119 309.582 112.926 309.442 112.792 309.223C112.658 309.004 112.621 308.77 112.681 308.52C112.741 308.27 112.88 308.078 113.099 307.943C113.303 307.806 113.53 307.767 113.781 307.827C114.031 307.887 114.223 308.027 114.357 308.246C114.491 308.465 114.529 308.699 114.469 308.949Z' fill='currentColor'/>
        <path d='M125.957 280.268L135.409 289.204L135.032 290.298L122.091 291.465L122.484 290.328L134.044 289.332L125.58 281.362L125.957 280.268Z' fill='currentColor'/>
        <path d='M145.205 264.927C144.8 265.79 144.284 266.55 143.657 267.209C143.031 267.867 142.416 268.322 141.813 268.573L141.213 267.714C141.788 267.45 142.36 267.033 142.928 266.465C143.503 265.882 143.967 265.214 144.32 264.461C144.828 263.379 145.023 262.501 144.905 261.827C144.793 261.139 144.422 260.647 143.792 260.351C143.34 260.139 142.916 260.115 142.521 260.28C142.132 260.432 141.78 260.684 141.464 261.037C141.147 261.39 140.75 261.897 140.273 262.558C139.723 263.337 139.237 263.944 138.815 264.381C138.393 264.817 137.896 265.127 137.325 265.31C136.754 265.493 136.119 265.421 135.421 265.092C134.571 264.693 134.037 264.015 133.817 263.059C133.59 262.083 133.781 260.945 134.392 259.644C134.714 258.959 135.128 258.326 135.635 257.745C136.142 257.165 136.652 256.736 137.167 256.46L137.767 257.318C137.246 257.608 136.772 257.996 136.344 258.48C135.916 258.964 135.563 259.501 135.287 260.089C134.817 261.089 134.649 261.929 134.781 262.61C134.912 263.29 135.28 263.772 135.883 264.055C136.362 264.281 136.806 264.314 137.215 264.155C137.61 263.99 137.973 263.734 138.303 263.388C138.626 263.021 139.039 262.497 139.543 261.814C140.086 261.05 140.566 260.456 140.982 260.033C141.391 259.59 141.87 259.281 142.421 259.105C142.972 258.929 143.583 258.999 144.254 259.314C145.144 259.733 145.683 260.438 145.87 261.428C146.057 262.419 145.835 263.585 145.205 264.927Z' fill='currentColor'/>
        <path d='M152.02 238.324L152.806 242.427L155.805 244.268L155.212 245.235L140.853 236.423L141.447 235.456L151.606 241.691L149.667 231.608L150.426 230.37L151.839 237.228L160.788 236.144L160.053 237.343L152.02 238.324Z' fill='currentColor'/>
        <path d='M168.106 205.726L174.934 218.829C175.475 219.831 175.731 220.705 175.702 221.453C175.683 222.188 175.421 222.876 174.916 223.519C174.58 223.947 174.191 224.295 173.749 224.564C173.316 224.82 172.86 224.972 172.381 225.018L172.053 224.039C172.915 223.947 173.612 223.562 174.145 222.884C174.5 222.432 174.679 221.947 174.681 221.429C174.684 220.912 174.489 220.277 174.097 219.527L173.365 218.143L160.787 215.04L161.53 214.095L172.797 216.918L167.391 206.636L168.106 205.726Z' fill='currentColor'/>
        <path d='M200.96 165.528L211.828 178.402L210.978 179.119L209.176 176.985C209.414 177.993 209.37 178.961 209.044 179.889C208.719 180.817 208.146 181.627 207.325 182.32C206.482 183.032 205.553 183.48 204.539 183.662C203.524 183.844 202.528 183.755 201.549 183.392C200.582 183.02 199.722 182.389 198.97 181.498C198.218 180.608 197.74 179.654 197.536 178.638C197.333 177.601 197.412 176.604 197.772 175.646C198.122 174.677 198.719 173.837 199.563 173.124C200.372 172.442 201.256 172.023 202.214 171.868C203.184 171.703 204.141 171.806 205.087 172.176L200.093 166.259L200.96 165.528ZM206.718 181.495C207.412 180.91 207.894 180.226 208.164 179.444C208.445 178.651 208.498 177.834 208.322 176.993C208.157 176.141 207.763 175.345 207.138 174.605C206.513 173.865 205.795 173.342 204.983 173.037C204.182 172.722 203.368 172.637 202.54 172.781C201.723 172.916 200.968 173.276 200.274 173.861C199.58 174.447 199.093 175.136 198.811 175.928C198.541 176.71 198.489 177.527 198.653 178.379C198.829 179.221 199.23 180.012 199.855 180.752C200.48 181.492 201.192 182.02 201.993 182.335C202.804 182.64 203.619 182.725 204.436 182.59C205.264 182.446 206.025 182.081 206.718 181.495Z' fill='currentColor'/>
        <path d='M234.162 155.272L225.616 160.82C226.145 161.578 226.791 162.142 227.555 162.513C228.31 162.87 229.106 163.012 229.942 162.938C230.791 162.857 231.609 162.56 232.395 162.049C233.043 161.629 233.565 161.128 233.962 160.545C234.363 159.941 234.6 159.292 234.671 158.596L235.631 158.866C235.538 159.666 235.252 160.42 234.773 161.128C234.308 161.827 233.694 162.424 232.933 162.919C231.943 163.561 230.921 163.918 229.867 163.989C228.813 164.06 227.816 163.859 226.877 163.386C225.951 162.904 225.17 162.175 224.535 161.197C223.909 160.233 223.551 159.238 223.462 158.213C223.378 157.166 223.557 156.193 224 155.292C224.443 154.39 225.115 153.647 226.015 153.062C226.916 152.477 227.862 152.17 228.853 152.14C229.848 152.089 230.796 152.313 231.697 152.811C232.611 153.301 233.381 154.028 234.008 154.993L234.162 155.272ZM226.56 153.9C225.862 154.353 225.337 154.92 224.985 155.6C224.624 156.267 224.458 156.997 224.487 157.791C224.52 158.563 224.742 159.322 225.153 160.065L232.766 155.122C232.266 154.436 231.663 153.925 230.958 153.589C230.252 153.253 229.517 153.107 228.753 153.153C227.989 153.198 227.257 153.447 226.56 153.9Z' fill='currentColor'/>
        <path d='M256.952 148.946C256.1 149.374 255.224 149.652 254.324 149.781C253.424 149.911 252.66 149.888 252.03 149.712L252.089 148.667C252.705 148.815 253.412 148.841 254.211 148.746C255.023 148.644 255.801 148.406 256.544 148.033C257.613 147.497 258.309 146.928 258.633 146.326C258.97 145.716 258.983 145.1 258.67 144.478C258.446 144.032 258.128 143.751 257.715 143.637C257.315 143.515 256.882 143.495 256.416 143.577C255.949 143.659 255.323 143.811 254.538 144.036C253.625 144.307 252.867 144.484 252.265 144.566C251.663 144.647 251.081 144.584 250.519 144.374C249.957 144.165 249.503 143.716 249.157 143.026C248.736 142.187 248.734 141.324 249.152 140.437C249.577 139.53 250.432 138.754 251.717 138.11C252.393 137.771 253.11 137.53 253.868 137.387C254.626 137.244 255.292 137.223 255.868 137.324L255.808 138.37C255.219 138.276 254.607 138.287 253.971 138.402C253.335 138.518 252.726 138.721 252.145 139.013C251.158 139.508 250.505 140.064 250.188 140.68C249.871 141.296 249.862 141.902 250.161 142.497C250.398 142.97 250.727 143.271 251.147 143.399C251.56 143.514 252.003 143.537 252.477 143.469C252.957 143.381 253.606 143.224 254.425 143C255.325 142.735 256.069 142.565 256.657 142.49C257.253 142.395 257.821 142.449 258.363 142.651C258.905 142.854 259.342 143.286 259.675 143.949C260.116 144.829 260.104 145.715 259.638 146.609C259.173 147.503 258.277 148.282 256.952 148.946Z' fill='currentColor'/>
        <path d='M272.692 128.712L273.749 128.3L278.063 139.338L277.006 139.75L272.692 128.712ZM272.171 125.82C271.931 125.914 271.697 125.916 271.467 125.827C271.232 125.724 271.067 125.552 270.973 125.313C270.88 125.073 270.884 124.836 270.987 124.6C271.09 124.365 271.262 124.201 271.501 124.107C271.741 124.014 271.978 124.019 272.214 124.122C272.443 124.211 272.605 124.375 272.699 124.615C272.792 124.855 272.788 125.092 272.685 125.327C272.582 125.562 272.41 125.727 272.171 125.82Z' fill='currentColor'/>
        <path d='M303.521 118.576L306.304 128.783C306.821 130.681 306.743 132.208 306.07 133.364C305.397 134.519 304.134 135.349 302.28 135.853C301.228 136.139 300.192 136.249 299.17 136.182C298.148 136.115 297.275 135.874 296.55 135.459L296.914 134.466C297.627 134.837 298.425 135.059 299.307 135.133C300.19 135.206 301.084 135.12 301.989 134.874C303.507 134.461 304.532 133.79 305.063 132.861C305.598 131.947 305.658 130.731 305.244 129.212L304.719 127.285C304.479 128.244 304.004 129.063 303.295 129.742C302.587 130.421 301.714 130.902 300.677 131.184C299.612 131.474 298.577 131.504 297.574 131.275C296.581 131.028 295.721 130.548 294.994 129.836C294.267 129.124 293.758 128.235 293.467 127.169C293.176 126.103 293.164 125.079 293.429 124.097C293.695 123.116 294.195 122.274 294.929 121.572C295.677 120.867 296.585 120.369 297.65 120.079C298.701 119.793 299.714 119.769 300.688 120.006C301.662 120.243 302.491 120.723 303.175 121.447L302.47 118.862L303.521 118.576ZM300.496 130.174C301.387 129.932 302.138 129.524 302.75 128.95C303.358 128.361 303.765 127.67 303.972 126.876C304.19 126.064 304.178 125.213 303.935 124.322C303.692 123.431 303.273 122.698 302.677 122.123C302.096 121.544 301.396 121.162 300.577 120.977C299.754 120.777 298.897 120.799 298.007 121.041C297.116 121.283 296.367 121.699 295.759 122.288C295.162 122.858 294.752 123.542 294.53 124.339C294.323 125.133 294.341 125.975 294.584 126.865C294.827 127.756 295.241 128.498 295.826 129.092C296.422 129.667 297.124 130.057 297.932 130.26C298.751 130.445 299.606 130.417 300.496 130.174Z' fill='currentColor'/>
        <path d='M331.511 112.844C332.98 112.643 334.2 112.911 335.172 113.649C336.144 114.386 336.74 115.557 336.961 117.162L337.903 124.021L336.779 124.175L335.849 117.405C335.667 116.086 335.192 115.128 334.423 114.53C333.668 113.931 332.692 113.713 331.492 113.877C330.113 114.066 329.083 114.627 328.401 115.56C327.72 116.493 327.475 117.657 327.667 119.051L328.526 125.303L327.401 125.457L325.789 113.718L326.868 113.57L327.217 116.111C327.509 115.231 328.02 114.505 328.75 113.931C329.496 113.356 330.416 112.993 331.511 112.844Z' fill='currentColor'/>
        <path d='M382.64 122.621C381.688 122.561 380.791 122.36 379.948 122.019C379.105 121.678 378.456 121.273 378 120.804L378.578 119.931C379.036 120.369 379.634 120.748 380.372 121.068C381.125 121.388 381.917 121.575 382.748 121.628C383.941 121.703 384.829 121.562 385.412 121.205C386.011 120.848 386.332 120.323 386.375 119.628C386.407 119.13 386.273 118.727 385.973 118.42C385.689 118.114 385.325 117.879 384.88 117.714C384.436 117.55 383.818 117.366 383.027 117.165C382.101 116.94 381.357 116.711 380.796 116.478C380.234 116.245 379.763 115.897 379.383 115.433C379.002 114.97 378.836 114.353 378.885 113.582C378.943 112.646 379.377 111.9 380.185 111.345C381.009 110.775 382.138 110.536 383.573 110.627C384.328 110.674 385.069 110.827 385.796 111.086C386.523 111.344 387.109 111.661 387.556 112.038L386.978 112.912C386.516 112.534 385.982 112.235 385.374 112.014C384.766 111.794 384.137 111.663 383.488 111.622C382.385 111.552 381.542 111.704 380.958 112.076C380.373 112.448 380.06 112.967 380.019 113.631C379.986 114.16 380.118 114.585 380.416 114.907C380.716 115.214 381.087 115.458 381.531 115.637C381.99 115.803 382.63 115.995 383.451 116.214C384.361 116.438 385.09 116.666 385.637 116.898C386.199 117.115 386.663 117.448 387.03 117.896C387.396 118.343 387.556 118.937 387.509 119.677C387.448 120.659 386.991 121.418 386.139 121.956C385.286 122.493 384.12 122.715 382.64 122.621Z' fill='currentColor'/>
        <path d='M410.533 124.967C410.206 125.19 409.814 125.341 409.356 125.42C408.916 125.487 408.465 125.483 408.002 125.408C406.986 125.244 406.246 124.84 405.782 124.198C405.321 123.541 405.171 122.712 405.332 121.711L406.564 114.071L404.412 113.723L404.567 112.759L406.719 113.107L407.13 110.553L408.251 110.734L407.839 113.288L411.559 113.89L411.404 114.854L407.684 114.252L406.471 121.781C406.348 122.542 406.433 123.154 406.727 123.615C407.038 124.064 407.552 124.347 408.269 124.463C409.001 124.581 409.641 124.462 410.189 124.106L410.533 124.967Z' fill='currentColor'/>
        <path d='M440.197 120.423L437.027 131.837L435.977 131.545L436.657 129.096C436.056 129.792 435.32 130.263 434.451 130.508C433.586 130.738 432.65 130.713 431.644 130.433C430.157 130.02 429.106 129.279 428.491 128.213C427.876 127.147 427.786 125.834 428.219 124.274L430.071 117.604L431.165 117.909L429.337 124.491C428.98 125.774 429.037 126.842 429.507 127.695C429.977 128.548 430.81 129.141 432.005 129.474C433.288 129.831 434.413 129.712 435.379 129.117C436.36 128.526 437.039 127.553 437.415 126.197L439.104 120.118L440.197 120.423Z' fill='currentColor'/>
        <path d='M473.752 127.236L467.186 142.742L466.161 142.307L467.25 139.737C466.458 140.404 465.575 140.801 464.6 140.93C463.625 141.059 462.642 140.914 461.653 140.494C460.636 140.063 459.816 139.436 459.195 138.614C458.573 137.792 458.203 136.862 458.083 135.825C457.977 134.794 458.151 133.743 458.605 132.67C459.06 131.597 459.694 130.74 460.508 130.1C461.341 129.451 462.266 129.071 463.283 128.96C464.306 128.834 465.326 128.988 466.343 129.419C467.319 129.833 468.092 130.432 468.664 131.216C469.25 132.007 469.591 132.908 469.689 133.919L472.707 126.793L473.752 127.236ZM462.114 139.58C462.95 139.935 463.778 140.056 464.598 139.944C465.432 139.837 466.185 139.515 466.856 138.978C467.541 138.447 468.073 137.736 468.45 136.844C468.828 135.953 468.969 135.076 468.874 134.214C468.793 133.358 468.5 132.593 467.997 131.919C467.507 131.252 466.844 130.74 466.008 130.386C465.172 130.031 464.337 129.907 463.503 130.014C462.682 130.126 461.93 130.448 461.245 130.979C460.573 131.516 460.049 132.23 459.671 133.122C459.294 134.014 459.146 134.887 459.227 135.744C459.322 136.606 459.615 137.37 460.104 138.038C460.608 138.712 461.278 139.226 462.114 139.58Z' fill='currentColor'/>
        <path d='M492.125 141.79L493.117 142.342L487.364 152.697L486.372 152.145L492.125 141.79ZM494.02 139.547C493.796 139.422 493.644 139.243 493.566 139.009C493.496 138.762 493.523 138.526 493.648 138.301C493.773 138.076 493.958 137.929 494.205 137.858C494.452 137.788 494.688 137.815 494.913 137.94C495.138 138.065 495.286 138.251 495.356 138.498C495.434 138.732 495.411 138.961 495.286 139.186C495.161 139.411 494.975 139.559 494.728 139.629C494.481 139.699 494.245 139.672 494.02 139.547Z' fill='currentColor'/>
        <path d='M507.781 165.374C506.862 164.733 506.177 163.942 505.727 163.001C505.289 162.068 505.118 161.082 505.215 160.043C505.321 158.991 505.703 157.994 506.361 157.051C507.019 156.108 507.818 155.412 508.759 154.962C509.709 154.499 510.698 154.313 511.724 154.402C512.763 154.5 513.742 154.869 514.661 155.51C515.579 156.151 516.258 156.938 516.696 157.871C517.146 158.813 517.319 159.809 517.213 160.861C517.116 161.9 516.738 162.891 516.08 163.834C515.423 164.777 514.619 165.479 513.669 165.942C512.728 166.392 511.737 166.568 510.698 166.47C509.672 166.381 508.699 166.015 507.781 165.374ZM508.365 164.537C509.11 165.057 509.895 165.346 510.721 165.406C511.559 165.474 512.362 165.315 513.13 164.929C513.91 164.551 514.577 163.965 515.131 163.171C515.685 162.377 516.004 161.548 516.089 160.685C516.187 159.831 516.059 159.022 515.705 158.259C515.364 157.505 514.821 156.867 514.077 156.348C513.332 155.828 512.54 155.534 511.702 155.466C510.876 155.406 510.073 155.565 509.293 155.943C508.525 156.329 507.864 156.92 507.31 157.714C506.757 158.508 506.431 159.332 506.333 160.186C506.248 161.049 506.376 161.858 506.717 162.613C507.071 163.376 507.62 164.017 508.365 164.537Z' fill='currentColor'/>
        <path d='M546.727 191.585C546.451 191.304 546.315 190.971 546.319 190.586C546.322 190.201 546.469 189.865 546.761 189.579C547.041 189.303 547.374 189.167 547.759 189.171C548.144 189.174 548.475 189.317 548.751 189.597C549.027 189.878 549.163 190.211 549.16 190.596C549.156 190.982 549.014 191.312 548.734 191.588C548.442 191.874 548.104 192.015 547.719 192.012C547.334 192.008 547.003 191.866 546.727 191.585Z' fill='currentColor'/>
        <path d='M590.013 228.001L576.743 236.731L576.219 235.935L581.79 219.306L570.264 226.888L569.627 225.921L582.897 217.191L583.434 218.007L577.85 234.617L589.376 227.034L590.013 228.001Z' fill='currentColor'/>
        <path d='M596.671 261.357L592.214 252.189C591.397 252.62 590.758 253.191 590.296 253.903C589.849 254.609 589.61 255.381 589.581 256.22C589.558 257.072 589.752 257.92 590.162 258.764C590.5 259.459 590.933 260.039 591.463 260.505C592.012 260.979 592.628 261.294 593.31 261.45L592.924 262.37C592.142 262.178 591.428 261.801 590.785 261.238C590.148 260.69 589.631 260.007 589.234 259.19C588.717 258.128 588.489 257.07 588.548 256.015C588.606 254.96 588.928 253.996 589.513 253.123C590.105 252.263 590.924 251.579 591.972 251.07C593.006 250.568 594.038 250.336 595.066 250.375C596.114 250.421 597.059 250.719 597.898 251.271C598.738 251.822 599.393 252.58 599.863 253.547C600.333 254.513 600.522 255.49 600.43 256.477C600.358 257.471 600.02 258.384 599.416 259.217C598.817 260.062 598.001 260.737 596.967 261.239L596.671 261.357ZM598.965 253.983C598.601 253.235 598.103 252.643 597.472 252.21C596.854 251.769 596.15 251.514 595.359 251.444C594.588 251.381 593.808 251.507 593.02 251.823L596.991 259.991C597.733 259.58 598.314 259.045 598.734 258.386C599.154 257.728 599.389 257.017 599.438 256.253C599.487 255.488 599.329 254.732 598.965 253.983Z' fill='currentColor'/>
        <path d='M618.654 286.453L602.708 291.864L602.35 290.811L604.994 289.914C603.969 289.766 603.083 289.371 602.338 288.729C601.592 288.087 601.047 287.257 600.701 286.24C600.345 285.194 600.267 284.165 600.467 283.155C600.667 282.144 601.114 281.249 601.808 280.47C602.507 279.705 603.408 279.136 604.511 278.762C605.614 278.387 606.676 278.291 607.696 278.472C608.736 278.663 609.636 279.1 610.396 279.785C611.17 280.466 611.735 281.329 612.091 282.375C612.432 283.378 612.5 284.354 612.295 285.302C612.095 286.265 611.65 287.12 610.961 287.865L618.289 285.378L618.654 286.453ZM601.69 285.976C601.982 286.836 602.443 287.535 603.073 288.072C603.708 288.624 604.45 288.972 605.297 289.115C606.15 289.274 607.035 289.197 607.952 288.886C608.869 288.575 609.617 288.097 610.197 287.453C610.782 286.823 611.158 286.096 611.326 285.272C611.498 284.462 611.438 283.627 611.146 282.768C610.853 281.908 610.39 281.202 609.755 280.65C609.125 280.113 608.383 279.765 607.531 279.607C606.683 279.463 605.8 279.547 604.883 279.858C603.966 280.169 603.216 280.64 602.631 281.27C602.051 281.914 601.675 282.641 601.502 283.451C601.335 284.275 601.398 285.116 601.69 285.976Z' fill='currentColor'/>
        <path d='M621.029 313.902C621.331 315.338 621.193 316.519 620.614 317.445C620.051 318.367 619.058 318.977 617.637 319.275L610.284 320.817L610.06 319.751L612.126 319.318C611.367 319.09 610.72 318.662 610.185 318.031C609.666 317.398 609.301 316.585 609.093 315.593C608.822 314.305 608.91 313.219 609.359 312.336C609.81 311.469 610.532 310.931 611.524 310.722C612.487 310.521 613.336 310.698 614.071 311.255C614.825 311.824 615.36 312.864 615.678 314.374L616.515 318.351L617.447 318.155C618.528 317.929 619.288 317.46 619.726 316.75C620.167 316.054 620.27 315.143 620.033 314.018C619.871 313.248 619.582 312.535 619.166 311.88C618.753 311.24 618.273 310.722 617.726 310.326L618.409 309.603C619.048 310.072 619.595 310.684 620.047 311.44C620.515 312.192 620.842 313.013 621.029 313.902ZM610.036 315.558C610.239 316.52 610.632 317.288 611.217 317.862C611.804 318.45 612.556 318.826 613.471 318.989L615.648 318.533L614.817 314.578C614.577 313.438 614.195 312.652 613.671 312.221C613.151 311.804 612.528 311.672 611.802 311.824C611.047 311.982 610.506 312.39 610.18 313.045C609.869 313.698 609.821 314.536 610.036 315.558Z' fill='currentColor'/>
        <path d='M613.454 342.376C613.358 341.427 613.409 340.509 613.608 339.622C613.808 338.735 614.101 338.029 614.489 337.503L615.445 337.932C615.087 338.455 614.811 339.106 614.616 339.886C614.423 340.681 614.368 341.493 614.452 342.321C614.572 343.51 614.856 344.364 615.304 344.882C615.753 345.414 616.324 345.646 617.017 345.576C617.514 345.526 617.889 345.329 618.143 344.984C618.399 344.654 618.571 344.257 618.661 343.791C618.751 343.326 618.831 342.687 618.901 341.874C618.972 340.923 619.076 340.152 619.214 339.561C619.352 338.969 619.619 338.448 620.014 337.997C620.41 337.547 620.991 337.283 621.759 337.206C622.692 337.112 623.499 337.419 624.179 338.126C624.876 338.847 625.296 339.922 625.441 341.353C625.517 342.106 625.487 342.861 625.351 343.62C625.215 344.379 624.997 345.01 624.698 345.511L623.742 345.083C624.04 344.566 624.248 343.99 624.366 343.354C624.484 342.719 624.511 342.077 624.445 341.43C624.334 340.331 624.047 339.523 623.584 339.007C623.121 338.491 622.558 338.266 621.896 338.333C621.369 338.386 620.971 338.585 620.702 338.932C620.448 339.277 620.268 339.683 620.163 340.15C620.075 340.63 619.99 341.292 619.908 342.137C619.836 343.072 619.73 343.828 619.59 344.405C619.467 344.995 619.215 345.507 618.833 345.941C618.451 346.375 617.891 346.629 617.154 346.703C616.175 346.802 615.351 346.474 614.682 345.72C614.012 344.966 613.603 343.851 613.454 342.376Z' fill='currentColor'/>
        <path d='M626.878 370.395C626.856 371.877 626.408 373.043 625.532 373.892C624.657 374.741 623.41 375.153 621.791 375.128L614.869 375.021L614.886 373.886L621.717 373.992C623.049 374.013 624.067 373.688 624.773 373.018C625.479 372.363 625.841 371.43 625.859 370.22C625.88 368.828 625.48 367.724 624.66 366.91C623.84 366.095 622.726 365.676 621.319 365.655L615.01 365.557L615.026 364.422L631.867 364.683L631.85 365.818L624.383 365.702C625.194 366.123 625.813 366.738 626.24 367.547C626.682 368.356 626.895 369.305 626.878 370.395Z' fill='currentColor'/>
        <path d='M619.144 401.361L616.787 397.91L613.304 397.417L613.462 396.294L630.14 398.652L629.981 399.776L618.181 398.107L623.979 406.583L623.777 408.022L619.746 402.294L611.974 406.857L612.17 405.463L619.144 401.361Z' fill='currentColor'/>
        <path d='M619.791 428.289L619.519 429.391L608.015 426.547L608.287 425.445L619.791 428.289ZM622.454 429.532C622.515 429.282 622.649 429.089 622.854 428.952C623.074 428.82 623.308 428.784 623.558 428.846C623.808 428.908 623.999 429.049 624.132 429.268C624.265 429.488 624.3 429.723 624.239 429.973C624.177 430.223 624.037 430.414 623.817 430.546C623.612 430.683 623.384 430.72 623.135 430.658C622.885 430.597 622.693 430.456 622.561 430.236C622.428 430.016 622.392 429.781 622.454 429.532Z' fill='currentColor'/>
        <path d='M610.777 458.137L601.384 449.139L601.769 448.047L614.717 446.965L614.317 448.099L602.75 449.02L611.162 457.045L610.777 458.137Z' fill='currentColor'/>
        <path d='M591.43 473.351C591.84 472.491 592.361 471.734 592.992 471.079C593.623 470.425 594.241 469.975 594.846 469.727L595.44 470.59C594.863 470.85 594.288 471.263 593.717 471.828C593.138 472.407 592.67 473.072 592.311 473.822C591.796 474.901 591.596 475.778 591.709 476.453C591.817 477.141 592.184 477.635 592.813 477.936C593.263 478.151 593.687 478.178 594.083 478.015C594.473 477.866 594.827 477.616 595.146 477.265C595.464 476.914 595.864 476.41 596.347 475.752C596.901 474.977 597.391 474.373 597.816 473.939C598.241 473.505 598.74 473.198 599.312 473.019C599.884 472.84 600.518 472.917 601.215 473.25C602.062 473.655 602.592 474.336 602.805 475.293C603.026 476.271 602.827 477.408 602.208 478.705C601.882 479.387 601.463 480.017 600.953 480.595C600.442 481.172 599.929 481.598 599.412 481.871L598.818 481.008C599.341 480.721 599.818 480.337 600.249 479.856C600.68 479.375 601.036 478.84 601.316 478.253C601.792 477.257 601.966 476.418 601.839 475.736C601.711 475.055 601.347 474.571 600.746 474.283C600.268 474.055 599.824 474.019 599.414 474.175C599.018 474.338 598.654 474.591 598.322 474.935C597.997 475.3 597.58 475.821 597.071 476.5C596.523 477.261 596.04 477.852 595.621 478.272C595.209 478.713 594.727 479.019 594.176 479.191C593.624 479.363 593.013 479.289 592.344 478.969C591.456 478.545 590.922 477.837 590.742 476.845C590.561 475.853 590.791 474.689 591.43 473.351Z' fill='currentColor'/>
        <path d='M584.441 499.909L583.682 495.8L580.694 493.94L581.294 492.976L595.594 501.883L594.995 502.846L584.877 496.544L586.75 506.64L585.982 507.873L584.614 501.005L575.658 502.031L576.402 500.837L584.441 499.909Z' fill='currentColor'/>
        <path d='M568.141 532.4L561.399 519.253C560.865 518.248 560.614 517.371 560.648 516.624C560.672 515.889 560.939 515.202 561.448 514.563C561.787 514.137 562.178 513.792 562.622 513.526C563.056 513.272 563.513 513.124 563.993 513.08L564.314 514.062C563.452 514.148 562.752 514.529 562.215 515.204C561.857 515.653 561.675 516.137 561.669 516.654C561.663 517.172 561.854 517.807 562.241 518.561L562.964 519.949L575.521 523.134L574.771 524.075L563.523 521.178L568.862 531.495L568.141 532.4Z' fill='currentColor'/>
        <path d='M535.023 572.383L524.241 559.438L525.095 558.726L526.882 560.872C526.652 559.863 526.702 558.895 527.033 557.969C527.365 557.043 527.943 556.236 528.768 555.549C529.617 554.842 530.549 554.401 531.564 554.225C532.579 554.05 533.575 554.146 534.552 554.515C535.517 554.893 536.372 555.53 537.118 556.426C537.864 557.321 538.336 558.278 538.533 559.295C538.729 560.334 538.644 561.33 538.278 562.285C537.921 563.252 537.319 564.089 536.47 564.795C535.657 565.473 534.77 565.886 533.811 566.035C532.84 566.193 531.883 566.084 530.94 565.708L535.895 571.657L535.023 572.383ZM529.37 556.378C528.672 556.959 528.186 557.639 527.911 558.42C527.624 559.21 527.566 560.027 527.737 560.87C527.896 561.722 528.285 562.521 528.905 563.265C529.525 564.01 530.24 564.537 531.05 564.847C531.848 565.168 532.662 565.258 533.491 565.119C534.309 564.99 535.067 564.635 535.764 564.054C536.461 563.473 536.954 562.787 537.24 561.997C537.515 561.216 537.573 560.4 537.415 559.547C537.244 558.704 536.849 557.911 536.229 557.166C535.609 556.422 534.899 555.89 534.101 555.57C533.291 555.259 532.478 555.169 531.66 555.298C530.831 555.437 530.067 555.797 529.37 556.378Z' fill='currentColor'/>
        <path d='M501.756 582.42L510.337 576.928C509.814 576.166 509.171 575.598 508.41 575.223C507.657 574.86 506.862 574.713 506.025 574.781C505.176 574.858 504.356 575.149 503.566 575.654C502.917 576.07 502.391 576.568 501.99 577.148C501.585 577.749 501.344 578.398 501.269 579.093L500.31 578.817C500.409 578.017 500.699 577.265 501.182 576.56C501.653 575.864 502.27 575.271 503.034 574.782C504.028 574.145 505.053 573.795 506.107 573.731C507.162 573.667 508.158 573.875 509.093 574.354C510.016 574.842 510.792 575.576 511.421 576.558C512.041 577.527 512.392 578.524 512.474 579.55C512.552 580.596 512.366 581.569 511.917 582.467C511.469 583.365 510.792 584.104 509.887 584.683C508.983 585.262 508.035 585.563 507.044 585.586C506.048 585.631 505.102 585.401 504.204 584.897C503.294 584.401 502.528 583.669 501.908 582.7L501.756 582.42ZM509.349 583.842C510.049 583.393 510.578 582.83 510.935 582.152C511.299 581.488 511.47 580.758 511.447 579.965C511.419 579.192 511.202 578.432 510.796 577.686L503.151 582.579C503.646 583.268 504.245 583.783 504.949 584.124C505.652 584.465 506.386 584.615 507.15 584.575C507.915 584.534 508.648 584.29 509.349 583.842Z' fill='currentColor'/>
        <path d='M478.925 588.595C479.78 588.174 480.657 587.901 481.558 587.778C482.459 587.654 483.223 587.682 483.852 587.862L483.786 588.907C483.171 588.755 482.464 588.724 481.665 588.814C480.852 588.911 480.073 589.143 479.326 589.511C478.254 590.04 477.555 590.605 477.227 591.205C476.885 591.812 476.869 592.428 477.177 593.052C477.398 593.5 477.715 593.783 478.127 593.9C478.526 594.024 478.959 594.047 479.426 593.968C479.893 593.89 480.52 593.741 481.306 593.522C482.221 593.256 482.98 593.085 483.583 593.007C484.185 592.929 484.767 592.997 485.328 593.209C485.888 593.422 486.34 593.875 486.681 594.567C487.097 595.409 487.092 596.272 486.668 597.156C486.238 598.06 485.378 598.83 484.089 599.466C483.41 599.8 482.692 600.037 481.933 600.175C481.174 600.312 480.508 600.329 479.933 600.224L479.999 599.179C480.587 599.277 481.2 599.27 481.837 599.159C482.473 599.047 483.083 598.848 483.667 598.56C484.657 598.072 485.313 597.52 485.634 596.906C485.955 596.292 485.968 595.687 485.674 595.089C485.439 594.614 485.112 594.311 484.694 594.18C484.281 594.063 483.838 594.037 483.364 594.102C482.883 594.187 482.233 594.339 481.413 594.558C480.511 594.817 479.766 594.982 479.177 595.053C478.581 595.144 478.013 595.087 477.473 594.881C476.932 594.675 476.498 594.24 476.169 593.574C475.734 592.692 475.752 591.806 476.223 590.915C476.695 590.024 477.595 589.251 478.925 588.595Z' fill='currentColor'/>
        <path d='M463.052 608.726L461.993 609.132L457.751 598.066L458.811 597.66L463.052 608.726ZM463.554 611.621C463.795 611.529 464.029 611.529 464.258 611.619C464.493 611.724 464.656 611.897 464.748 612.137C464.84 612.377 464.834 612.614 464.73 612.849C464.625 613.083 464.453 613.247 464.213 613.339C463.972 613.43 463.735 613.424 463.5 613.319C463.271 613.229 463.111 613.063 463.019 612.823C462.927 612.583 462.933 612.345 463.037 612.111C463.142 611.877 463.314 611.713 463.554 611.621Z' fill='currentColor'/>
        <path d='M432.157 618.66L429.442 608.435C428.937 606.534 429.024 605.007 429.705 603.856C430.386 602.705 431.654 601.883 433.512 601.391C434.565 601.112 435.602 601.01 436.624 601.083C437.645 601.157 438.517 601.404 439.239 601.823L438.868 602.814C438.158 602.438 437.361 602.211 436.479 602.132C435.597 602.052 434.703 602.133 433.796 602.373C432.275 602.776 431.246 603.44 430.709 604.365C430.168 605.276 430.1 606.492 430.504 608.013L431.016 609.944C431.263 608.986 431.743 608.17 432.456 607.495C433.169 606.821 434.045 606.346 435.083 606.071C436.151 605.788 437.186 605.765 438.188 606C439.179 606.254 440.036 606.74 440.758 607.456C441.481 608.173 441.984 609.065 442.267 610.133C442.551 611.201 442.557 612.225 442.285 613.205C442.013 614.185 441.507 615.024 440.769 615.72C440.016 616.421 439.105 616.913 438.038 617.196C436.985 617.475 435.972 617.493 434.999 617.249C434.027 617.006 433.201 616.52 432.522 615.792L433.21 618.381L432.157 618.66ZM435.258 607.082C434.366 607.318 433.612 607.722 432.996 608.292C432.384 608.877 431.972 609.565 431.76 610.357C431.537 611.168 431.544 612.019 431.781 612.912C432.018 613.804 432.432 614.54 433.025 615.119C433.602 615.701 434.3 616.088 435.117 616.279C435.939 616.484 436.796 616.468 437.688 616.232C438.58 615.995 439.332 615.584 439.944 615C440.545 614.433 440.958 613.752 441.185 612.956C441.398 612.164 441.385 611.322 441.148 610.429C440.911 609.537 440.502 608.792 439.921 608.195C439.329 607.616 438.629 607.222 437.823 607.013C437.005 606.822 436.15 606.845 435.258 607.082Z' fill='currentColor'/>
        <path d='M404.13 624.209C402.66 624.401 401.441 624.124 400.474 623.38C399.507 622.636 398.918 621.461 398.709 619.856L397.811 612.99L398.937 612.844L399.822 619.619C399.995 620.94 400.464 621.901 401.229 622.504C401.98 623.108 402.955 623.332 404.155 623.176C405.536 622.996 406.57 622.442 407.257 621.513C407.944 620.585 408.197 619.422 408.015 618.027L407.197 611.769L408.322 611.623L409.858 623.373L408.777 623.513L408.445 620.97C408.148 621.848 407.632 622.571 406.897 623.14C406.148 623.71 405.226 624.067 404.13 624.209Z' fill='currentColor'/>
        <path d='M353.066 614.096C354.017 614.163 354.913 614.369 355.754 614.716C356.594 615.063 357.241 615.472 357.694 615.944L357.11 616.813C356.656 616.372 356.06 615.989 355.324 615.665C354.573 615.339 353.782 615.147 352.952 615.089C351.759 615.006 350.87 615.141 350.284 615.495C349.684 615.847 349.359 616.37 349.311 617.065C349.277 617.563 349.408 617.967 349.705 618.276C349.987 618.583 350.35 618.821 350.794 618.989C351.237 619.156 351.854 619.343 352.643 619.55C353.568 619.781 354.31 620.015 354.87 620.252C355.43 620.488 355.899 620.839 356.276 621.305C356.653 621.772 356.816 622.39 356.762 623.16C356.697 624.096 356.259 624.839 355.448 625.389C354.62 625.953 353.489 626.185 352.055 626.085C351.3 626.032 350.56 625.874 349.835 625.611C349.11 625.348 348.525 625.027 348.081 624.647L348.665 623.777C349.124 624.158 349.656 624.461 350.263 624.685C350.869 624.91 351.497 625.045 352.146 625.09C353.248 625.167 354.093 625.021 354.679 624.652C355.266 624.284 355.582 623.767 355.628 623.103C355.665 622.575 355.535 622.149 355.239 621.825C354.942 621.516 354.572 621.27 354.13 621.087C353.671 620.919 353.032 620.722 352.214 620.498C351.304 620.268 350.577 620.035 350.032 619.8C349.471 619.579 349.009 619.243 348.646 618.793C348.282 618.343 348.126 617.748 348.177 617.008C348.245 616.027 348.707 615.271 349.563 614.739C350.419 614.207 351.587 613.993 353.066 614.096Z' fill='currentColor'/>
        <path d='M325.188 611.569C325.517 611.348 325.91 611.199 326.368 611.123C326.809 611.059 327.26 611.066 327.723 611.144C328.737 611.315 329.475 611.723 329.934 612.368C330.391 613.028 330.536 613.858 330.368 614.858L329.086 622.491L331.235 622.853L331.074 623.815L328.925 623.453L328.496 626.005L327.377 625.816L327.806 623.265L324.09 622.638L324.251 621.676L327.967 622.302L329.23 614.781C329.357 614.02 329.276 613.408 328.986 612.945C328.678 612.494 328.165 612.208 327.449 612.088C326.718 611.964 326.077 612.079 325.527 612.431L325.188 611.569Z' fill='currentColor'/>
        <path d='M295.495 615.918L298.74 604.524L299.788 604.823L299.092 607.268C299.698 606.576 300.437 606.11 301.307 605.871C302.174 605.646 303.109 605.677 304.113 605.963C305.598 606.387 306.644 607.134 307.252 608.204C307.86 609.275 307.942 610.588 307.499 612.145L305.603 618.802L304.511 618.491L306.382 611.921C306.747 610.641 306.697 609.572 306.233 608.716C305.769 607.86 304.94 607.261 303.746 606.921C302.466 606.555 301.34 606.667 300.37 607.255C299.385 607.839 298.7 608.808 298.315 610.162L296.587 616.229L295.495 615.918Z' fill='currentColor'/>
        <path d='M261.985 608.885L268.653 593.423L269.675 593.864L268.569 596.427C269.365 595.766 270.251 595.374 271.227 595.251C272.203 595.129 273.184 595.281 274.171 595.707C275.185 596.145 276.001 596.777 276.617 597.603C277.233 598.429 277.598 599.361 277.711 600.399C277.81 601.43 277.629 602.481 277.167 603.551C276.706 604.62 276.066 605.473 275.248 606.108C274.41 606.752 273.483 607.126 272.465 607.23C271.442 607.349 270.423 607.189 269.408 606.751C268.435 606.331 267.666 605.727 267.099 604.938C266.519 604.144 266.183 603.241 266.092 602.229L263.027 609.335L261.985 608.885ZM273.704 596.617C272.87 596.257 272.043 596.131 271.222 596.238C270.387 596.339 269.633 596.656 268.958 597.188C268.269 597.715 267.733 598.423 267.35 599.312C266.966 600.201 266.819 601.077 266.909 601.939C266.985 602.796 267.272 603.563 267.771 604.24C268.257 604.911 268.916 605.426 269.75 605.786C270.584 606.146 271.418 606.276 272.253 606.175C273.074 606.068 273.828 605.751 274.517 605.224C275.192 604.692 275.721 603.981 276.104 603.092C276.488 602.203 276.642 601.33 276.566 600.473C276.476 599.61 276.189 598.844 275.704 598.173C275.204 597.496 274.538 596.977 273.704 596.617Z' fill='currentColor'/>
        <path d='M243.709 594.21L242.72 593.652L248.541 583.335L249.53 583.893L243.709 594.21ZM241.798 596.441C242.022 596.567 242.173 596.748 242.249 596.982C242.318 597.229 242.289 597.465 242.163 597.689C242.037 597.913 241.85 598.06 241.602 598.128C241.355 598.197 241.119 598.168 240.895 598.042C240.671 597.915 240.525 597.728 240.456 597.481C240.379 597.247 240.404 597.018 240.531 596.794C240.657 596.57 240.844 596.423 241.091 596.354C241.339 596.286 241.574 596.314 241.798 596.441Z' fill='currentColor'/>
        <path d='M228.208 570.524C229.122 571.171 229.802 571.967 230.246 572.911C230.678 573.847 230.842 574.834 230.738 575.872C230.625 576.923 230.237 577.918 229.573 578.856C228.909 579.795 228.105 580.486 227.161 580.93C226.208 581.386 225.218 581.566 224.192 581.47C223.154 581.366 222.177 580.99 221.263 580.343C220.349 579.696 219.676 578.904 219.244 577.969C218.799 577.024 218.633 576.026 218.746 574.976C218.85 573.938 219.234 572.949 219.898 572.01C220.562 571.072 221.37 570.374 222.323 569.918C223.268 569.474 224.259 569.305 225.297 569.409C226.323 569.505 227.293 569.877 228.208 570.524ZM227.618 571.358C226.877 570.833 226.093 570.538 225.268 570.473C224.43 570.399 223.626 570.553 222.856 570.934C222.073 571.307 221.402 571.889 220.843 572.679C220.284 573.47 219.959 574.296 219.868 575.158C219.765 576.012 219.888 576.822 220.236 577.587C220.573 578.344 221.111 578.985 221.853 579.509C222.594 580.034 223.384 580.333 224.221 580.407C225.047 580.472 225.851 580.318 226.633 579.945C227.404 579.564 228.069 578.978 228.628 578.188C229.187 577.397 229.518 576.575 229.621 575.721C229.712 574.859 229.589 574.05 229.253 573.293C228.905 572.527 228.36 571.882 227.618 571.358Z' fill='currentColor'/>
    </svg>
)