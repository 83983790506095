import React from 'react'
import { InstagramIcon, FacebookIcon } from '../../../icons'

export const Contacts = ({ contacts, language }) => (
    <div className='footer__contacts'>
        <h2 className='footer__contacts-header'>
            <a href={(language === 'ua') ? `/contacts` : `/${language}/contacts`}>
                {contacts.header}
            </a>
        </h2>
        <address className='footer__contacts-list'>
            <div className='footer__contacts-list__item'>
                <a href={contacts.address.link} target='_blank'>
                    {contacts.address.text}
                    <br />
                    {contacts.address.location}
                </a>
            </div>
            <div className='footer__contacts-list__item'>
                <a href={`mailto:${contacts.email}`} target='_blank'>{contacts.email}</a>
            </div>
            <div className='footer__contacts-list__item'>
                <span>{contacts.client.header}</span>
                <br />
                <a href={`tel:${contacts.client.phone}`} target='_blank'>{contacts.client.phone}</a>
            </div>
            <div className='footer__contacts-list__item'>
                <span>{contacts.contractor.header}</span>
                <br />
                <a href={`tel:${contacts.contractor.phone}`} target='_blank'>{contacts.contractor.phone}</a>
            </div>
            <div className='footer__contacts-list__item'>
                <a className='footer__social-link' href={contacts.instagram}>
                    <InstagramIcon className='footer__social-image'/>
                </a>
                <a className='footer__social-link' href={contacts.facebook}>
                    <FacebookIcon className='footer__social-image'/>
                </a>
            </div>
        </address>
    </div>
)