import React from 'react'
import { Contacts } from './Contacts'
import { RequestForm } from '../forms/requestForm'
import { getTextByKey } from '../../../utils'
import { NewCircleStudio } from '../../../icons'
import './style.css'

export const Footer = ({ texts, contactsData, language }) => {
    const contacts = {
        header : getTextByKey(texts, 'contacts-header'),
        address: {
            link: contactsData['addressLink'],
            text: contactsData['address'],
            location: contactsData['location'] || 'Київ, Україна'
        },
        email : contactsData['email'],
        client: {
            header: getTextByKey(texts, 'contacts-clients-header'),
            phone : contactsData['clientNumber']
        },
        contractor : {
            header: getTextByKey(texts, 'contacts-contractor-header'),
            phone : contactsData['contractorNumber']
        },
        facebook : getTextByKey(texts, 'contacts-facebook-link'),
        instagram: getTextByKey(texts, 'contacts-instagram-link')
    }

    const request = {
        header : getTextByKey(texts, 'contacts-form-header'),
        name   : getTextByKey(texts, 'contacts-name-placeholder'),
        phone  : getTextByKey(texts, 'contacts-phone-placeholder'),
        email  : getTextByKey(texts, 'contacts-email-placeholder'),
        message: getTextByKey(texts, 'contacts-message-placeholder'),
        button : getTextByKey(texts, 'contacts-button-more'),
        thanks : getTextByKey(texts, 'contacts-button-thanks')
    }

    return (
        <footer className='footer container'>
            <Contacts
                contacts = {contacts}
                language = {language}
            />
            <RequestForm
                requestForm = {request}
            />
            <div className='footer__circle'>
                <NewCircleStudio className='footer__circle-icon'/>
            </div>
        </footer>
    )
}