import React from 'react'
import { Helmet } from 'react-helmet'
import { Seo, Meta } from '../../types'

export const SEO = (props: Seo) => {
	const { description, keywords, lang, meta, title } = props;

	const metaKeywords = keywords || ''
	const defaultMeta: Meta[] = [ {
		name   : `description`,
		content: description
	}, {
		name   : `keywords`,
		content: metaKeywords
	}, {
		name: `og:title`,
		content : title
	}, {
		name: `og:description`,
		content : description
	}, {
		name: `og:image`,
		content : `./images/og.png`
	}, {
		name: `og:type`,
		content : `website`
	}, {
		name   : `twitter:card`,
		content: `summary`
	}, {
		name   : `twitter:creator`,
		content: `n-design.studio`
	}, {
		name   : `twitter:title`,
		content: title
	}, {
		name   : `twitter:description`,
		content: description
	} ]
	const metaData: Meta[] = meta ? defaultMeta.concat(meta) : defaultMeta

	return (
		<Helmet>
			<title>{title}</title>
			<meta
				name='theme-color'
				content='#2c394f'
			/>
			<meta
				name='keywords'
				content={metaKeywords}
			/>

			{metaData && (
				metaData.map((data) => (
					<meta
						name={data.name}
						content={data.content}
					/>
				))
			)}

			{`<!-- Hotjar Tracking Code for Ndesign -->`}
			<script>
				{`(function(h,o,t,j,a,r){
					h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
					h._hjSettings={hjid:3765080,hjsv:6};
					a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');r.async=1;
					r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
					a.appendChild(r);
				})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
			</script>
		</Helmet>
	)
}
