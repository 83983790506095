import React, { useEffect, useState } from 'react'
import './Nav.css'

export const Nav = ({ language, projects, services, about, contacts, isCollapsed }) => {
    const [ windowWidth, setWindowWidth ] = useState<number>(727)

    useEffect(() => {
        setWindowWidth(window.outerWidth)
    }, [])

    return (
        <nav
            className='header__nav-list'
            style = {{
                display: `${windowWidth <= 768 ? (!isCollapsed ? 'none' : 'flex') : 'flex'}`
            }}
        >
            <a
                href={(language === 'ua') ? `/projects` : `/${language}/projects`}
                className='header__nav-link'
            >
                {projects}
            </a>
            <a
                href={(language === 'ua') ? `/services` : `/${language}/services`}
                className='header__nav-link'
            >
                {services}
            </a>
            <a
                href={(language === 'ua') ? `/about` : `/${language}/about`}
                className='header__nav-link'
            >
                {about}
            </a>
            <a
                href={(language === 'ua') ? `/contacts` : `/${language}/contacts`}
                className='header__nav-link'
            >
                {contacts}
            </a>
        </nav>
    )
}