import { Text } from "../types"

export const getMainTeamTexts = (texts: Text[], siteImages) => ({
    header: texts.find((t: Text) => t.key === 'main-team-header')?.value ?? 'Основна команда',
    names: {
        pavlo: {
            name: texts.find((t: Text) => t.key === 'main-team-name-pavlo')?.value ?? '',
            description: texts.find((t: Text) => t.key === 'main-team-description-pavlo')?.value,
        },
        nataliia: {
            name: texts.find((t: Text) => t.key === 'main-team-name-nataliia')?.value ?? '',
            description: texts.find((t: Text) => t.key === 'main-team-description-nataliia')?.value,
        },
        yehor: {
            name: texts.find((t: Text) => t.key === 'main-team-name-yehor')?.value ?? '',
            description: texts.find((t: Text) => t.key === 'main-team-description-yehor')?.value,
        }
    },
    images: {
        pavlo: siteImages.find(s => s.key === 'main-team-image-pavlo'),
        nataliia: siteImages.find(s => s.key === 'main-team-image-nataliia'),
        yehor: siteImages.find(s => s.key === 'main-team-image-yehor'),
    },
})